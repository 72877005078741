import styled from "styled-components";
import pxIntoRem from "utils/pxIntoRem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import 'swiper/css/pagination';

const IndexReviewsBlock = styled.div`
  position: relative;
  width: calc(100% - ${pxIntoRem(70)});
  margin: 0 ${pxIntoRem(35)};
  @media (max-width: 500px) {
    margin: 0;
    width: auto;
  }
`;

const IndexReviewsBackgroundBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: ${pxIntoRem(814)};
  @media (max-width: 500px) {
    height: auto;
  }
`;

const IndexReviewsTitle = styled.h2`
  color: #FFF;
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(38)};
  font-style: normal;
  font-weight: 550;
  line-height: 100%;
  margin-top: ${pxIntoRem(150)};
  .mobile_break {
    display: none;
  }
  @media (max-width: 500px) {
    margin-top: ${pxIntoRem(100)};
    font-size: ${pxIntoRem(24)};
    .mobile_break {
      display: block;
    }
  }
`;
const IndexReviewsSwiperBlock = styled.div`
  position: relative;
  width: ${pxIntoRem(1310)};
  margin-top: ${pxIntoRem(60)};
  @media (max-width: 500px) {
    margin: ${pxIntoRem(30)} ${pxIntoRem(15)} ${pxIntoRem(100)} ${pxIntoRem(15)};
    width: calc(100vw - ${pxIntoRem(30)});
  }
`;

const IndexReviewsSwiper = styled(Swiper)`
  width: 100%;
`;

const IndexReviewsSwiperSlide = styled(SwiperSlide)`
  display: block;
  background: transparent;
`;

const IndexReviewsSwiperSlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: ${pxIntoRem(20)};
  border: 0.8px solid #202230;
  background: rgba(15, 16, 28, 0.50);
  box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(${pxIntoRem(90)});
  height: ${pxIntoRem(384)};
  @media (max-width: 500px) {
    height: auto;
  }
`;

const IndexReviewsSwiperSlideUserInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pxIntoRem(16)};
  align-items: center;
  margin: ${pxIntoRem(30)} 0 ${pxIntoRem(20)} ${pxIntoRem(30)};
  @media (max-width: 500px) {
    margin: ${pxIntoRem(24)} 0 ${pxIntoRem(16)} ${pxIntoRem(24)};
  }
`;

const IndexReviewsSwiperSlideUserAvatar = styled.div`
  display: flex;
  width: ${pxIntoRem(35)};
  height: ${pxIntoRem(35)};
  border-radius: 50%;
  background: #1B1D2C;
  margin-left: auto;
  margin-right: ${pxIntoRem(6)};
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.30);
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(18)};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media (max-width: 500px) {
    width: ${pxIntoRem(25)};
    height: ${pxIntoRem(25)};
    font-size: ${pxIntoRem(14)};
  }
`;

const IndexReviewsSwiperSlideUserName = styled.p`
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(18)};
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 500px) {
    font-size: ${pxIntoRem(16)};
  }
`;

const IndexReviewsSwiperSlideText = styled.p`
  color: #848097;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(16)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: ${pxIntoRem(360)};
  margin-left: ${pxIntoRem(30)};
  margin-right: ${pxIntoRem(30)};
  &.review_date {
    margin-top: auto;
    margin-bottom: ${pxIntoRem(30)};
  }
  @media (max-width: 500px) {
    font-size: ${pxIntoRem(14)};
    margin-left: ${pxIntoRem(24)};
    margin-right: ${pxIntoRem(24)};
    width: calc(100% - ${pxIntoRem(48)});
    &.review_date {
      margin-top: ${pxIntoRem(24)};
      margin-bottom: ${pxIntoRem(24)};
    }
  }
`;

export {
  IndexReviewsBlock,
  IndexReviewsBackgroundBlock,
  IndexReviewsTitle,
  IndexReviewsSwiperBlock,
  IndexReviewsSwiper,
  IndexReviewsSwiperSlide,
  IndexReviewsSwiperSlideContent,
  IndexReviewsSwiperSlideUserInfo,
  IndexReviewsSwiperSlideUserAvatar,
  IndexReviewsSwiperSlideUserName,
  IndexReviewsSwiperSlideText
};