export const getSlicedFilename = (name: string): string => {
    if (name.length > 15) {
        if (window.innerWidth <= 500) {
            return name.slice(0,9) + "..." + name.slice(-3);
        } else {
            return name.slice(0,12) + "..." + name.slice(-3);
        }
    } else {
        return name;
    }
}