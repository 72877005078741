import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const uploadProcessSlice = createSlice({
    name: 'uploadProcess',
    initialState: {
        value: false
    },
    reducers: {
        setUploadProcess: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        }
    }
})

export const { setUploadProcess } = uploadProcessSlice.actions;

export default uploadProcessSlice.reducer;