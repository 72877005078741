export const userReviews = [
    {
        avatarUrl: "/images/userAvatar.png",
        username: "Максим",
        text: "Использую Semantix, чтобы сделать расшифровки интервью для статей. Раньше делал это вручную, тратил много времени, часто срывал дедлайны. А теперь транскрибирую запись за несколько минут! Сервис делает максимально точную расшифровку. Иногда, когда нужно что-то поправить, исправляю прямо в браузере, очень удобно. Рекомендую!",
        reviewDate: "12.12.2024"
    },
    {
        avatarUrl: "/images/userAvatar.png",
        username: "Елена Васильевна",
        text: "Работаю адвокатом и использую Semantix, чтобы делать расшифровки аудиозаписей для суда. Сервис отличный, расшифровывает без ошибок и довольно быстро. Теперь трачу намного меньше времени на подготовку к заседанию и могу делать свою работу более качественно.",
        reviewDate: "12.12.2024"
    },
    {
        avatarUrl: "/images/userAvatar.png",
        username: "Оля",
        text: "Учусь в университете, не всегда успеваю писать конспекты, поэтому делаю расшифровки лекций в Semantix. Для студента сервис очень доступный по стоимости. За пару минут получаю грамотный текст и готовлюсь по нему к парам и экзаменам. Преподаватели мной довольны, а я довольна Semantix!!!",
        reviewDate: "12.12.2024"
    },
    {
        avatarUrl: "/images/userAvatar.png",
        username: "Коршунова Ева",
        text: "Меня Semantix очень выручает в работе. Я фрилансер, часто созваниваюсь с заказчиками и экспертами. А тратить время и вручную расшифровывать записи наших разговоров совсем не хочется. Поэтому использую нейросеть Smantix. Даже созвон длиной в час он транскрибирует меньше чем за 10 минут. Я довольна и рекомендую Semantix коллегам.",
        reviewDate: "12.12.2024"
    },
    {
        avatarUrl: "/images/userAvatar.png",
        username: "Алексей Сергеевич",
        text: "Я юрист, и по работе иногда нужно сделать расшифровку видео и аудио. Раньше обращался к фрилансерам, но их услуги стоят довольно дорого. Как же хорошо, что я нашел Semantix. Теперь расшифровка обходится мне намного дешевле, а точность у нейросети такая же, как у человека.",
        reviewDate: "12.12.2024"
    },
    {
        avatarUrl: "/images/userAvatar.png",
        username: "Вадим",
        text: "У меня свой блог на YouTube, и мне нужно готовить субтитры для видео. С Semantix делать это быстро, удобно и дешево. Закидываю видео в программу, жду несколько минут и скачиваю в нужном формате. Потом сразу добавляю в видео — и готово! Очень рад, что нашел этот сервис.",
        reviewDate: "12.12.2024"
    }
];