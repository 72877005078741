export const seoBlockContent = {
    "/": [{
        title: "Транскрибация видеозаписей",
        text1st: "Кроме аудио, вы легко можете расшифровать видеозаписи: качество будет одинаково высоким в обоих случаях. Программа транскрибации поддерживает разные форматы записей: MP4, MKV, FLV, AVI, MOV, WMV.",
        text2nd: "Переведенный в текст звук можно использовать в качестве субтитров. Так вы сделаете видео более интересным, наглядным, а также доступным людям с ограниченными возможностями. Еще увеличите время просмотра примерно на 50%.",
        text3rd: "Кроме того, расшифрованный текст можно вставить в описание к видеофайлу, чтобы увеличить плотность ключевых слов, их разнообразие. Благодаря этому поисковые системы будут лучше ранжировать контент."
    }, {
        title: "Какие форматы поддерживает Semantix",
        text1st: "Вы можете использовать разные форматы для автоматического перевода речи в текст:",
        list1stInnerHtml: "Видео: <span>MP4, MKV, FLV, AVI, MOV, WMV</span>",
        list2ndInnerHtml: "Аудио: <span>M4A, MP3, OGG, AAC, WAV, FLAC, WMA</span>",
        text4th: "Это наиболее распространенные варианты, но если ваш формат не поддерживается, напишите нам на почту: ",
        linkName: "hello@semantix.one",
        href: "mailto:hello@semantix.one"
    }, {
        title: "Искусственный интеллект для любых целей",
        text1st: "Транскрибируйте аудио в текст для использования в разных сферах:",
        subParagraph: [{
            title: "Образование",
            text1st: "Конвертируйте в текст вебинары, записи лекций выступлений и используйте текст при подготовке домашних заданий, курсовых и дипломных работ."
        }, {
            title: "Бизнес",
            text1st: "Воспользуйтесь онлайн-инструментом, чтобы получить тексты разговоров с клиентами и деловыми партнерами. Информация поможет принимать более взвешенные решения."
        }, {
            title: "Фриланс",
            text1st: "Расшифровывайте файлы с записями переговоров с заказчиками, чтобы было проще использовать информацию в процессе работы."
        }, {
            title: "Журналистика и копирайтинг",
            text1st: "Автоматически переводите записи интервью в текст, чтобы быстрее и легче готовить материалы к публикации."
        }, {
            title: "Медицина",
            text1st: "Переводите в текст опросы пациентов, чтобы владеть более точной информацией об их состоянии."
        }]
    }],
    "/convert-audio2text": [{
        title: "Мы заботимся о безопасности данных",
        text1st: "Онлайн-сервис Semantix дорожит доверием клиентов. Мы делаем все, чтобы вы безопасно загружали файлы, хранили и скачивали их. Поэтому мы защищаем данные согласно ведущим практикам в сфере искусственного интеллекта.",
        subParagraph: [{
            title: "Шифрование",
            text1st: "Вся информация шифруется в соответствии с протоколами безопасности."
        }, {
            title: "Аутентификация",
            text1st: "Каждый пользователь проходит процедуру аутентификации, чтобы в личный кабинет не проникли третьи лица."
        }, {
            title: "Резервное копирование",
            text1st: "Все файлы, которые вы загружаете и расшифровываете, хранятся на наших облачных серверах."
        }]
    }, {
        title: "С какими форматами работает онлайн-сервис",
        text1stInnerHtml: "В Semantix можно загрузить такие форматы аудио: <span>M4A, MP3, OGG, AAC, WAV, FLAC, WMA</span>. Также программа конвертирует в текст видео форматов MP4, MKV, FLV, AVI, MOV, WMV. Это наиболее распространенные виды файлов. Но если нужного вам формата нет, напишите в службу поддержки онлайн-сервиса —<a href='mailto:hello@semantix.one'>hello@semantix.one</a>. Также по этому адресу вы можете задать интересующий вопрос об использовании сервиса или предложить идеи по его улучшению.",
        text4th: "После транскрибации и редактирования вы можете скачать текст в формате docx и txt, а также str для использования в качестве субтитров в видеороликах."
    }, {
        title: "Нейросеть для расшифровки видео в текст",
        text1st: "Кроме транскрибирования аудио в текст, с помощью онлайн-сервиса Semantix можно расшифровать речь в видео. Нейросеть обрабатывает ролики разных форматов: MP4, MKV, FLV, AVI, MOV, WMV.",
        text2nd: "Транскрибировать видео в текст нужно, чтобы:",
        subParagraph: [{
            title: "1. Повысить доступность контента",
            text1st: "Использование текста помогает людям с ограничениями по слуху или зрению получать доступ к информации с видеозаписи."
        }, {
            title: "2. Оптимизировать контент для поисковых систем",
            text1st: "Текст помогает поисковикам проще и быстрее анализировать содержание видео, делать поиск по ключевым словам. Все это продвигает видео в поисковых системах."
        }, {
            title: "3. Сделать автоматические субтитры",
            text1st: "Расшифрованный текст можно скачать в формате srt и вставить в качестве субтитров в видео на YouTube."
        }],
        text4th: "Получить текст из видео можно так же, как и расшифровать аудио."
    }, {
        title: "ИИ для транскрибации речи в текст для любых задач",
        subParagraph: [{
            title: "1. Медицинская сфера",
            text1st: "Нейросеть для распознавания текста используют при транскрибации медицинских консультаций, описания жалоб пациентов, операционных отчетов и других аудиозаписей. Это упрощает и ускоряет работу медицинского персонала."
        }, {
            title: "2. Образование",
            text1st: "Автоматический перевод аудио в текст помогает студентам с нарушениями слуха или языковыми барьерами получать доступ к учебному контенту, лекциям и аудиозаписям."
        }, {
            title: "3. Судебная практика",
            text1st: "Программу используют для транскрибации судебных слушаний, заседаний, записей допросов и других файлов, что помогает судебным органам в работе с документацией."
        }, {
            title: "4. Медиа и развлечения",
            text1st: "Онлайн-сервис на основе ИИ помогает в создании субтитров для видео, транскрибации интервью, подкастов, радиопередач и аудиокниг. Благодаря этому обрабатывать материалы можно проще и быстрее."
        }, {
            title: "5. Бизнес и маркетинг",
            text1st: "Нейросети помогают в транскрибации переговоров, конференций, вебинаров, обзоров продуктов и переводе других аудио в текстовую версию. Это облегчает анализ и обработку информации для принятия взвешенных бизнес-решений."
        }, {
            title: "6. Техническая поддержка и обслуживание",
            text1st: "Транскрибатор помогает автоматизировать обработку запросов клиентов, переводить в текст записи звонков и голосовых сообщений. Так получится проще анализировать работу техподдержки, понимать ожидания целевой аудитории, принимать важные для компании решения."
        }, ]
    }],
    "/video2text": [{
        title: "Для чего используют онлайн-транскрибацию видео",
        text1st: "Semantix активно применяют в разных сферах, среди них:",
        subParagraph: [{
            title: "1. Журналистика и медиа",
            text1st: "В киноиндустрии, на телевидении, радио и в других медийных сферах расшифровку видео в текст используют для создания субтитров, озвучки, транскрибации интервью, а также для анализа контента и оценки его эффективности."
        }, {
            title: "2. Образование",
            text1st: "В школах, университетах и исследовательских центрах расшифровка аудио и видео в текст помогает делать пояснения для видеоуроков и лекций, анализировать исследовательские данные, а также улучшать доступность образовательного контента."
        }, {
            title: "3. Здравоохранение",
            text1st: "В медицинских учреждениях транскрибация видео в текст востребована для документирования медицинских консультаций, процедур и операций, а также для обучения медицинского персонала."
        }, {
            title: "4. Бизнес и маркетинг",
            text1st: "Текстовые расшифровки видео помогают анализировать рекламные ролики, вебинары, записи совещаний в Zoom и Skype, презентации и другие материалы, а также создавать субтитры для корпоративных видеороликов."
        }, {
            title: "5. Юриспруденция",
            text1st: "Расшифровку видео в текст используют для документирования судебных процессов, прослушивания записей разговоров, судебных прений, анализа доказательств и других целей."
        }, {
            title: "6. IT",
            text1st: "В сфере информационных технологий транскрибация видеофайлов о новых разработках помогает лучше понять продукт, а также разобраться в сложных терминах."
        }, {
            title: "7. SEO-оптимизация сайтов",
            text1st: "Расшифровка видео с Youtube и других площадок позволяет создавать субтитры и описания с ключевыми словами для лучшего продвижения в поисковых системах."
        }]
    }],
    "/convert-video2text": [{
            title: "Когда нужно конвертировать видео в текст",
            text1st: "Перечислим, для чего переводить видеозаписи в текст:",
            subParagraph: [{
                    title: "1. Улучшение восприятия материала",
                    text1st: "Текстовая версия видео делает контент доступным для людей с нарушениями слуха."
                },
                {
                    title: "2. Создание иностранных субтитров",
                    text1st: "Расшифровку можно использовать в качестве субтитров для людей, говорящих на другом языке."
                },
                {
                    title: "3. Повышение удобства просмотра",
                    text1st: "Многие пользователи смотрят видео без звука, поэтому текстовые субтитры помогут им комфортнее потреблять контент."
                },
                {
                    title: "4. SEO-оптимизация",
                    text1st: "Расшифрованный текст можно добавить в описание к видео, и тогда поисковые системы будут лучше ранжировать контент."
                },
                {
                    title: "5. Анализ видео",
                    text1st: "С помощью текста быстрее и легче искать информацию в видео, анализировать ее, передавать коллегам."
                },
                {
                    title: "6. Создание другого контента",
                    text1st: "Субтитры могут быть полезны при разработке сценариев, написании статей и генерации других материалов."
                },
                {
                    title: "7. Удобное хранение",
                    text1st: "С помощью конвертера можно сохранить информацию из видео в текстовом формате."
                }
            ]
        },
        {
            title: "Применение нейросети для расшифровки видео в текст",
            text1st: "В каких сферах полезен конвертер:",
            subParagraph: [{
                    title: "1. Образование",
                    text1st: "Расшифровка видео лекций и вебинаров позволяет лучше усваивать учебный материал, помогает готовиться к экзаменам, семинарам, писать дипломные и курсовые работы."
                },
                {
                    title: "2. Медицина",
                    text1st: "Транскрибацию видео используют для документирования консультаций, операций, исследований, а также в других ситуациях, когда важно иметь текстовый источник для последующего анализа."
                },
                {
                    title: "3. Маркетинг и PR",
                    text1st: "Перевод видео и аудио в текст помогает при создании контента для социальных сетей, блогов, написании пресс-релизов, а также для SEO-оптимизации сайтов и расширения аудитории."
                },
                {
                    title: "4. Юриспруденция",
                    text1st: "В юридической сфере часто нужны текстовые варианты консультаций, судебных заседаний, допросов и других процедур."
                },
                {
                    title: "5. Исследования и аналитика",
                    text1st: "В научных и маркетинговых исследованиях, социологических опросах автоматический перевод видео в текст нужен для дальнейшей обработки и интерпретации информации."
                }
            ]
        }
    ],
    "/generate-subtitles": [{
            title: "1. Безопасность использования генератора",
            text1st: "Мы заботимся о том, чтобы к файлам наших клиентов не получили доступ третьи лица. Поэтому защищаем данные несколькими способами:",
            subParagraph: [{
                    title: "1. Шифрование",
                    text1st: "Все аудио, видео, тексты шифруются согласно проверенным протоколам безопасности."
                },
                {
                    title: "2. Аутентификация",
                    text1st: "Чтобы получить доступ к личному кабинету в генераторе, нужно пройти аутентификацию."
                },
                {
                    title: "3. Резервное копирование",
                    text1st: "Все данные находятся в надежном облачном хранилище."
                }
            ],
            text4th: "Благодаря внутренней системе безопасности, вы можете использовать автоматический генератор Semantix и не волноваться за сохранность данных."
        },
        {
            title: "2. Как работает программа для создания субтитров онлайн",
            text1st: "Автоматический генератор субтитров преобразует видео в текст благодаря встроенной технологии искусственного интеллекта. Нейросеть анализирует звук, определяет язык, распознает акценты, диалекты, выявляет посторонние шумы. Затем создает точную текстовую расшифровку видео, расставляет знаки препинания для более удобного использования материала.",
            text2nd: "Субтитры автоматически синхронизируются с видео, также инструмент добавляет тайм-коды. Можно редактировать текст, чтобы повысить точность перевода и обеспечить читабельность."
        },
        {
            title: "Для чего в видео нужны субтитры",
            text1st: "1. Субтитры в видео выполняют несколько задач:",
            text2nd: "2. Делают диалоги в подкастах, репортажах, интервью более четкими и понятными.",
            text3rd: "3. Помогают оптимизировать видео под требования поисковых систем. Если добавить субтитры в описания роликов на YouTube и других площадках, поисковики будут лучше ранжировать контент.",
            text4th: "4. Позволяют смотреть видео зрителям из разных стран. Благодаря этому получится расширить географию просмотра и увеличить охват.",
            text5th: "5. Делают видео инклюзивными. Автоматическое создание субтитров позволяет слабослышащим людям без ограничений просматривать видео.",
            text6th: "6. Повышают вовлеченность пользователей. Контент с субтитрами смотреть интереснее и проще. Поэтому автоматические субтитры помогают привлекать и удерживать пользователей.",
            text7th: "7. Компенсируют низкое качество звука и изображения. Субтитры делают видео с низким разрешением или высоким уровнем шума более понятными и доступными."
        },
        {
            title: "Как создать субтитры: советы",
            text1st: "1. Сделайте субтитры читабельными. Распределите текст по экрану равномерно. Так зрителям будет легче читать его и одновременно смотреть видео. Проверьте, чтобы субтитры не менялись слишком быстро, и пользователи могли понять суть написанного. Оптимальная скорость — до 140 слов в минуту.",
            text2nd: "2. Проверьте расшифровку. После того как генератор создаст субтитры, перечитайте получившийся текст. Также после размещения субтитров в видео проверьте, соответствуют ли они изображению в текущий момент.",
            text3rd: "3. Сделайте субтитры хорошо просматриваемыми. Выберите такой цвет и стиль шрифта, чтобы автоматические субтитры выделялись на фоне видео. Но при этом они не должны заслонять собой изображение. Если субтитры — это реплики, для каждого персонажа выберите отдельный цвет.",
            text4th: "4. Учитывайте особенности платформы. Когда загружаете видео на разных сайтах, размер субтитров обычно меняется. Учитывайте это, если публикуете ролики на нескольких площадках."
        },
        {
            title: "Когда используют генератор субтитров",
            text1st: "Кому и когда пригодится нейросеть для создания субтитров:",
            subParagraph: [{
                    title: "Создание подкастов",
                    text1st: "Добавление субтитров позволит сделать беседы более понятными, а также оптимизировать выпуски под SEO, чтобы Яндекс и Google лучше ранжировали их. Для этого загрузите расшифрованный текст в описание к видео."
                },
                {
                    title: "Выпуск игровых видео",
                    text1st: "Целевой аудитории проще следить за ходом игры, если есть автоматические субтитры. Так все происходящее в видео будет понятно, даже если звук плохой, или слышна речь нескольких игроков."
                },
                {
                    title: "Съемка кино и сериалов",
                    text1st: "В киноиндустрии не обойтись без субтитров. Онлайн-генератор автоматически создает качественные субтитры и экономит время специалистов."
                },
                {
                    title: "Создание видео для людей с нарушением слуха",
                    text1st: "С помощью генератора субтитров можно сделать видео доступными для всех зрителей. Это касается разных роликов: записей лекций, развлекательных видео, сериалов, интервью и т. д."
                },
                {
                    title: "Продвижение видео в социальных сетях",
                    text1st: "Большинство пользователей соцсетей смотрят видео и сторис без звука. Чтобы повысить охваты, стоит использовать автоматические субтитры."
                }
            ]
        }
    ],
    "/convert-court-audiofiles": [{
            title: "Для чего нужна стенограмма аудиозаписи судебного заседания",
            text1st: "Часто расшифровка судебного заседания является самым главным и единственным документом, в котором содержится вся важная информация о деле. Именно в нем отражен ход судебного процесса, доказательства, прения сторон. Но в формате аудиозаписи нередко сложно отразить суть процесса, и в таком случае нужна расшифровка из суда. Часто она становится альтернативой судебным протоколам.",
            text2nd: "Обычно адвокаты, юристы и их клиенты заказывают услугу расшифровки в Semantix, чтобы:",
            list1st: "получить полную, дословную и грамотную расшифровку заседания суда;",
            list2nd: "проанализировать аргументы, позицию сторон, показания свидетелей;",
            list3rd: "иметь под рукой документ со всеми репликами оппонентов;",
            list4th: "при необходимости демонстрировать суду, что происходило в определенный момент времени на прошлых заседаниях;",
            list5th: "делать обоснованные, качественные замечания в процессе слушаний;",
            list6th: "оспаривать или доказывать законность принятых в ходе судебного разбирательства решений в высших инстанциях.",
            text4th: "Мы работаем как с частными клиентами, так и с юридическими компаниями. Наш сервис — универсальный: расшифровку заказывают все стороны судебного процесса — и обвинение, и защита. Также к нам обращаются судьи и обычные люди, которым предстоит судебный процесс."
        },
        {
            title: "Какие записи можно расшифровать в текст для суда",
            text1st: "Сервис поможет расшифровать аудиозаписи, которые пригодятся в ходе предварительного разбирательства, судебного процесса или при оспаривании решения суда. Среди них:",
            text2nd: "1. Аудиозаписи судебных заседаний — выступления сторон, свидетельские показания, решения суда.",
            text3rd: "2. Аудиозаписи допросов свидетелей, потерпевших, подозреваемых, а также других участников дела.",
            text4th: "3. Аудиозаписи телефонных разговоров. Эти материалы могут быть использованы в качестве доказательств в судебных процессах.",
            text5th: "4. Аудиозаписи экспертных заключений, прозвучавших в рамках судебного разбирательства или данных в процессе сбора доказательств.",
            text6th: "5. Видеозаписи онлайн-конференций с участниками, которые не смогли посетить зал суда лично и давали показания удаленно.",
            text7th: "6. Аудиозаписи судебных решений — приговоров, постановлений и иных юридически значимых моментов заседаний.",
            text8th: "7. Аудиозаписи судебных консультаций с адвокатами, юристами и другими специалистами, предоставленные в рамках судебных дел."
        },
        {
            title: "Особенности стенограммы для суда",
            text1st: "Записи судебных заседаний и материалов для суда требуют более качественной обработки, чем все остальные. Профессиональная расшифровка процессуальной деятельности должна быть максимально точной, чтобы грамотно представить доказательства и таким образом повлиять на исход процесса.",
            text2nd: "Semantix учитывает эти требования, поэтому обеспечивает:",
            list1st: "Нормализацию аудио. В процессе расшифровки программа минимизирует шум, отличает разные голоса друг от друга, а также юридические термины.",
            list2nd: "Полную расшифровку записи. В готовом документе содержатся все реплики, в том числе диалоговые.",
            list3rd: "Расстановка тайм-кодов. В расшифровке указано время, чтобы легко сопоставить текст и аудиозапись, находить нужные моменты при подготовке к заседанию.",
            list4th: "Дословный перевод. Программа не перефразирует реплики участников, а предоставляет дословную расшифровку.",
            list5th: "Полную конфиденциальность. Semantix надежно защищает данные пользователей и их файлы, не передает информацию третьим лицам."
        }
    ],
    "/audio-video2text": [{
            title: "Для чего транскрибировать аудио и видео",
            subParagraph: [{
                    title: "Сможете сосредоточиться на разговоре, а не на конспектировании",
                    text1st: "Человек упускает около 30% информации, когда во время разговора или прослушивания выступления конспектирует что-то. Но с помощью сервиса транскрибации онлайн вы можете просто записывать встречи на видео или сохранять их в аудиоформате, а потом переводить файлы в текст. Благодаря этому вы не пропустите важную информацию и получите полную расшифровку, а не конспект отдельных тезисов."
                },
                {
                    title: "Получится переводить материалы на разные языки",
                    text1st: "С помощью мультиплатформенной программы Semantix вы сможете автоматически создавать субтитры на разных языках. Сервис способен обработать файлы на русском, английском, немецком, испанском и итальянском языках. Благодаря этому не придется вручную переводить учебные материалы, ролики с презентацией продукта, выступления спикеров и другие файлы: достаточно сделать пару кликов в сервисе и получить готовый текст."
                },
                {
                    title: "Сможете повысить доступность контента",
                    text1st: "Текст ранжируется в поисковых системах лучше, чем видео и аудио. Поэтому стоит транскрибировать их, чтобы добавить текстовое описание и улучшить SEO-оптимизацию вашего контента. Благодаря этому материалы увидит больше пользователей.",
                    text2nd: "Кроме того, многие люди смотрят видео без звука. Чтобы им было удобно, стоит добавить субтитры. Но делать это вручную долго, поэтому лучше загрузить файл в Semantix и получить результат за несколько минут."
                }
            ]
        },
        {
            title: "Кто может использовать конвертер аудио и видео в текст",
            subParagraph: [{
                    title: "1. Журналисты и редакторы",
                    text1st: "Для обработки файлов с интервью, записей пресс-конференций и других аудио- и видеоматериалов, чтобы в дальнейшем использовать их в своих статьях."
                },
                {
                    title: "2. Копирайтеры",
                    text1st: "Для создания контента на основе видео и аудио, например, для блогов, сайтов или рекламных кампаний."
                },
                {
                    title: "3. Блогеры",
                    text1st: "Для преобразования файлов в текст в рамках SEO-оптимизации, создания скриптов для видео или статей для блогов."
                },
                {
                    title: "4. Тренеры и коучи",
                    text1st: "Для транскрибирования файлов с тренингами, вебинарами или видеоуроками при разработке обучающих материалов и пособий."
                },
                {
                    title: "5. Медицинские работники",
                    text1st: "Для расшифровки записей консультаций и операций для документации и архивирования медицинской информации."
                },
                {
                    title: "6. Юристы и адвокаты",
                    text1st: "Для обработки файлов с записями судебных процессов, допросов и юридических консультаций при подготовке к суду или анализе правовой информации."
                },
                {
                    title: "7. Авторы и писатели",
                    text1st: "Для сохранения идей или заметок в аудио- или видеоформате, чтобы в дальнейшем использовать их в своей работе."
                },
                {
                    title: "8. Студенты и преподаватели",
                    text1st: "Для обработки файлов с лекциями, семинарами, выступлениями при подготовке к занятиям, написании учебных работ."
                }
            ]
        }
    ],
    "/mp4totext": [{
            title: "Особенности формата MP4",
            text1st: "MP4 (MPEG-4 Part 14) является одним из наиболее популярных форматов для хранения аудио и видео. Такие файлы поддерживает большинство устройств, программ и платформ, что делает MP4 идеальным для обмена и воспроизведения мультимедийного контента. Другие преимущества формата:",
            list1st: "небольшой размер при высоком качестве аудио- и видеофайлов, что делает файлы удобными для передачи и хранения;",
            list2nd: "возможность встраивания субтитров и метаданных, поэтому можно легко создать более интересные и наглядные видеоролики;",
            list3rd: "поддержка технологии защиты авторских прав (DRM), чтобы контролировать доступ к контенту и его использование;",
            list4th: "возможность хранить многоканальный звук, что делает формат идеальным для видео с многоканальным аудио.",
            text4th: "Благодаря этому файлы в формате MP4 можно точно расшифровать и получить текст для любых целей."
        },
        {
            title: "Для чего преобразовывать MP4 в текст",
            subParagraph: [{
                    title: "Транскрибирование интервью",
                    text1st: "Преобразование MP4 в текст позволяет создавать письменные версии разговоров, интервью и диалогов. Это удобно при анализе информации, цитировании, написании статей, составлении стенограмм."
                },
                {
                    title: "Создание субтитров",
                    text1st: "Текстовую версию видео- или аудиофайла можно использовать для создания субтитров. Они делают контент более наглядным, а также доступным для людей с нарушениями слуха."
                },
                {
                    title: "SEO-оптимизация",
                    text1st: "Текст из видео или аудио помогает улучшить ранжирование контента в поисковых системах. Это связано с тем, что поисковики лучше индексируют текст, чем другие типы контента."
                },
                {
                    title: "Обучение и образование",
                    text1st: "Расшифрованные тексты можно использовать для создания обучающих материалов, учебных пособий, тестов, выполнения домашних заданий, написания научных работ."
                },
                {
                    title: "Создание скриптов и сценариев",
                    text1st: "Тексты, извлеченные из аудио или видео, могут стать основой для написания скриптов, сценариев и другого контента."
                },
                {
                    title: "Юридические цели",
                    text1st: "Во время судебных процессов записи разговоров, допросов, показаний иногда лучше конвертировать в текст: так удобнее использовать информацию."
                }
            ]
        }
    ],
    "/mp3totext": [{
        title: "Как улучшить преобразование MP3 в текст",
        text1st: "Чтобы конвертировать аудио в формате MP3 более качественно, используйте эти рекомендации:",
        text2nd: "1. Транскрибируйте файлы, где человек говорит четко в микрофон. Если голос звучит неотчетливо, используйте дополнительные функции Semantix для улучшения аудио.",
        text3rd: "2. Расшифровывайте записи, где слышны голоса не более трех человек. Так текст будет максимально совпадать с речью.",
        text4th: "3. Проверяйте текст после преобразования. Сервис может допустить небольшие недочеты, особенно если аудиофайл плохого качества. Поэтому перечитывайте тексты: при необходимости внести изменения можно в браузере."
    }],
    "/convert-interviews": [{
            title: "Кто использует сервис для транскрибации интервью",
            subParagraph: [{
                title: "1. Журналисты",
                text1st: "Для быстрой подготовки интервью к публикации, а также других форматов контента: заметок, новостей, репортажей и тд."
            }, {
                title: "2. Копирайтеры, редакторы, маркетологи",
                text1st: "Для быстрой расшифровки файлов в видео- и аудиоформате, в которых содержится информация о проекте, разговоры с клиентами и тд."
            }, {
                title: "3. Ученые",
                text1st: "Для транскрибации интервью испытуемых, записей фокус-групп, исследовательских данных и других видео- и аудиоматериалов для анализа и обработки информации."
            }, {
                title: "4. Подкастеры",
                text1st: "Для транскрибации записей лекций, семинаров, выступлений преподавателей, чтобы быстрее и легче усваивать материал, готовиться к занятиям."
            }, {
                title: "5. Студенты",
                text1st: "Для транскрибации записей лекций, семинаров, выступлений преподавателей, чтобы быстрее и легче усваивать материал, готовиться к занятиям."
            }, {
                title: "6. Медицинские работники",
                text1st: "Для расшифровки медицинских консультаций, записей приемов, чтобы быстро и легко документировать и анализировать информацию."
            }, {
                title: "7. Юристы",
                text1st: "Для перевода аудио и видео судебных процессов, допросов, консультаций и других материалов в текст для более быстрой и точной подготовки документов, стенограмм, анализа правовой информации."
            }, {
                title: "8. Блогеры",
                text1st: "Для создания текстов своих видео, чтобы создавать субтитры, описания к роликам, улучшать SEO-показатели своего контента, делать его доступным для широкой аудитории."
            }, {
                title: "9. Руководители компаний и отделов",
                text1st: "Для транскрибации совещаний, интервью с соискателями, переговоров с сотрудниками или клиентами, чтобы анализировать данные и использовать их для подготовки отчетов, улучшения бизнес-процессов."
            }]
        },
        {
            title: "Почему стоит транскрибировать интервью онлайн",
            subParagraph: [{
                    title: "Быстрый результат",
                    text1st: "Можно получить качественную расшифровку файла с интервью в максимально короткий срок."
                },
                {
                    title: "Высокая точность",
                    text1st: "Сервис автоматически распознает речь с помощью технологии искусственного интеллекта, благодаря чему расшифровка интервью получается максимально точной."
                },
                {
                    title: "Удобство использования",
                    text1st: "Инструмент быстро обрабатывает любой объем информации, достаточно только загрузить в него нужные файлы с интервью."
                },
                {
                    title: "Экономия времени",
                    text1st: "Автоматическая расшифровка аудио и видео занимает намного меньше времени, чем перевод интервью в текст вручную."
                },
                {
                    title: "Конфиденциальность",
                    text1st: "Надежные онлайн-сервисы обеспечивают безопасность данных, что особенно важно при подготовке некоторых интервью."
                },
                {
                    title: "Доступность",
                    text1st: "Инструменты для автоматической расшифровки интервью можно использовать в любое время и из любого места."
                }
            ]
        }
    ]
};