import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const updateBalanceSlice = createSlice({
    name: 'updateBalance',
    initialState: {
        value: 0
    },
    reducers: {
        setUpdateBalance: (state, action: PayloadAction<number>) => {
            state.value = state.value + action.payload;
        }
    }
})

export const { setUpdateBalance } = updateBalanceSlice.actions;

export default updateBalanceSlice.reducer;