import { FC } from "react";
import {
    CookiePopupBlock,
    CookiePopupBlockBackgroundLayer,
    CookiePopupIcon,
    CookiePopupText,
    CookiePopupLink,
    CookiePopupMainButton
} from "./CookiePopupStyles";
import { setCookieAgreement } from "utils/cookieManager";

interface ICookiePopup {
    setShowCookiePopup: (state: boolean) => void;
}

const CookiePopup: FC<ICookiePopup> = ({setShowCookiePopup}) => {    
    const handleAcceptCookies = () => {
        setCookieAgreement();
        setShowCookiePopup(false);
    };

    return (
        <CookiePopupBlock>
            <CookiePopupBlockBackgroundLayer />
            <CookiePopupIcon alt="info" src="/images/info-icon.svg" />
            <CookiePopupText>
                Наш сайт использует куки. Продолжая им пользоваться, вы соглашаетесь на обработку персональных данных в соответствии с
                <CookiePopupLink to="/agreement">политикой конфиденциальности</CookiePopupLink>
            </CookiePopupText>
            <CookiePopupMainButton onClick={handleAcceptCookies}>
                Согласен
            </CookiePopupMainButton>
        </CookiePopupBlock>
    );
}

export default CookiePopup;