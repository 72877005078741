import {
    IndexGreetingBlock,
    IndexGreeting,
    IndexGreetingContent,
    IndexGreetingTitle,
    IndexGreetingText,
    IndexGreetingMainButton
} from "./IndexGreetingStyles";
import { useSelector } from "react-redux";
import { RootState } from "slices";
import useModal from "hooks/useModal";
import RegistrationModal from "components/Modals/RegistrationModal/RegistrationModal";
import PasswordRecoveryModal from "components/Modals/PasswordRecoveryModal/PasswordRecoveryModal";
import MessageModal from "components/Modals/MessageModal/MessageModal";
import { indexMainContent } from "content/IndexMainContent";
import { useLocation } from "react-router";
import { useLayoutEffect, useState } from "react";
import MainBackgroundSVG from "components/SvgComponents/MainBackgroundSVG";

interface IIndexGreetingContent {
    title: string;
    text: string;
}

const IndexGreetingComponent = () => {
    const widgetFileState = useSelector((state: RootState) => state.widgetFile.value);
    const [content, setContent] = useState<IIndexGreetingContent>(null);
    const [message, setMessage] = useState<string>(null);
    const location = useLocation();

    useLayoutEffect(() => {
        const content = indexMainContent[location.pathname];
        setContent(content);
    }, [location]);

    const {
        openModal: openMessModal,
        modal: messageModal
    } = useModal(MessageModal, { modalType: "message_modal", message });
    const {
        openModal: openRecModal,
        modal: passwordRecoveryModal
    } = useModal(PasswordRecoveryModal, { openMessModal, setMessage });
    const {
        openModal: openRegModal,
        modal: registrationModal
    } = useModal(RegistrationModal, { openRecModal, openMessModal, modalType: "registration", setMessage });

    if (content) {
        return (
            <IndexGreetingBlock>
                <MainBackgroundSVG />
                <IndexGreeting>
                    <IndexGreetingContent id="greeting_content">
                        <IndexGreetingTitle>
                            {content.title}
                        </IndexGreetingTitle>
                        <IndexGreetingText>
                            {content.text}
                        </IndexGreetingText>
                        {widgetFileState === "ready" && (
                            <IndexGreetingMainButton onClick={openRegModal}>
                                Полная версия
                            </IndexGreetingMainButton>
                        )}
                    </IndexGreetingContent>
                </IndexGreeting>
                {registrationModal}
                {passwordRecoveryModal}
                {messageModal}
            </IndexGreetingBlock>
        );
    }
}

export default IndexGreetingComponent;