import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const requestTimeoutSlice = createSlice({
    name: 'requestTimeout',
    initialState: {
        value: null
    },
    reducers: {
        setRequestTimeout: (state, action: PayloadAction<ReturnType<typeof setTimeout>>) => {
            state.value = action.payload;
        }
    }
})

export const { setRequestTimeout } = requestTimeoutSlice.actions;

export default requestTimeoutSlice.reducer;