import { Link } from "react-router-dom";
import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";

const LandingFooterBlock = styled.footer`
  position: relative;
  width: 100%;
  height: ${pxIntoRem(69)};
  z-index: 9;
  margin-top: auto;
  @media (max-width: 500px) {
    height: auto;
  }
`;

const LandingFooterBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: ${pxIntoRem(24)};
  padding-bottom: ${pxIntoRem(24)};
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const LandingFooterLine = styled.div`
  position: absolute;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: ${pxIntoRem(1)};
  background: linear-gradient(270deg, rgba(23, 26, 39, 0.00) 0%, #171A27 50.01%, rgba(23, 26, 39, 0.00) 100%);
`;

const LandingFooterStampWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: ${pxIntoRem(10)};
  margin-left: ${pxIntoRem(70)};
  margin-right: auto;
  @media (max-width: 500px) {
    margin: 0 auto;
  }
`;

const LandingFooterStamp = styled.div`
  color: #848097;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &.organisation_info {
    margin-left: ${pxIntoRem(30)};
  }
  @media (max-width: 500px) {
    margin: 0 auto ${pxIntoRem(30)} auto;
    &.organisation_info {
        display: none;
    }
  }
`;

const LandingFooterDocumentsNavigation = styled.nav`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: auto;
  margin-right: ${pxIntoRem(70)};
  gap: ${pxIntoRem(32)};
  @media (max-width: 500px) {
    position: relative;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    gap: ${pxIntoRem(10)};
    margin-bottom: ${pxIntoRem(30)};
  }
`;

const LandingFooterDocumentLink = styled(Link)`
  color: #848097;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: 0.3s;
  @media (min-width: 501px) {
    &:hover {
      color: #FFF;
      transition: 0.3s;
    }
  }
`;

const LandingFooterContactsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pxIntoRem(550)};
  @media (max-width: 500px) {
    width: 100vw;
    flex-direction: column;
    align-items: center;
    gap: ${pxIntoRem(16)};
  }
`;

const LandingFooterTelegramIcon = styled.svg`
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  fill: #FFF;
`;

const LandingFooterTelegramTitle = styled.div`
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const LandingFooterContactTelegramBlock = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: ${pxIntoRem(10)};
  margin-left: ${pxIntoRem(70)};
  transition: 0.3s;
  &:active ${LandingFooterTelegramIcon} {
    fill: #1683E2;
    transition: 0.3s;
  }
  &:active ${LandingFooterTelegramTitle} {
    color: #1683E2;
    transition: 0.3s;
  }
  @media (min-width: 501px) {
    &:hover ${LandingFooterTelegramIcon} {
      fill: #1683E2;
      transition: 0.3s;
    }
    &:hover ${LandingFooterTelegramTitle} {
      color: #1683E2;
      transition: 0.3s;
    }
  }
  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

const LandingFooterEmailIcon = styled.svg`
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  fill: #FFF;
`;

const LandingFooterEmailTitle = styled.div`
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const LandingFooterContactEmailBlock = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: ${pxIntoRem(10)};
  margin: ${pxIntoRem(13)} auto ${pxIntoRem(49)} ${pxIntoRem(70)};
  transition: 0.3s;
  &:active ${LandingFooterEmailIcon} {
    fill: #1683E2;
    transition: 0.3s;
  }
  &:active ${LandingFooterEmailTitle} {
    color: #1683E2;
    transition: 0.3s;
  }
  @media (min-width: 501px) {
    &:hover ${LandingFooterEmailIcon} {
      fill: #1683E2;
      transition: 0.3s;
    }
    &:hover ${LandingFooterEmailTitle} {
      color: #1683E2;
      transition: 0.3s;
    }
  }
  @media (max-width: 500px) {
    margin-left: auto;
    margin-bottom: 0;
  }
`;

const LandingFooterLinksBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  @media (max-width: 500px) {
    display: none;
  }
`;

const LandingFooterLogoLink = styled.div`
  width: ${pxIntoRem(138)};
  height: ${pxIntoRem(31)};
  margin: ${pxIntoRem(32)} auto ${pxIntoRem(98)} ${pxIntoRem(70)};
  z-index: 99;
  @media (max-width: 500px) {
    width: ${pxIntoRem(116)};
    height: ${pxIntoRem(27)};
    margin: ${pxIntoRem(24)} 0 ${pxIntoRem(24)} ${pxIntoRem(15)};
  }
`;

const LandingFooterLogo = styled.img`
  width: ${pxIntoRem(138)};
  height: ${pxIntoRem(31)};
  @media (max-width: 500px) {
    width: ${pxIntoRem(116)};
    height: ${pxIntoRem(27)};
  }
`;

const LandingFooterCommercialPagesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${pxIntoRem(440)} calc(100% - ${pxIntoRem(440)});
  grid-template-rows: ${pxIntoRem(18)} ${pxIntoRem(18)} ${pxIntoRem(18)} ${pxIntoRem(18)} ${pxIntoRem(18)};
  margin-top: ${pxIntoRem(45)};
  grid-gap: ${pxIntoRem(20)};
`;

const LandingFooterCommercialPagesLink = styled(Link)`
  color: #79768B;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: normal;  
  transition: 0.3s;
  &.active_link {
    color: #FFF;
  }
  &:hover {
    color: #FFF;
    transition: 0.3s;
  }
`;

const LandingFooterMobileWrapper = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
  }
`;

export {
    LandingFooterBlock,
    LandingFooterBody,
    LandingFooterLine,
    LandingFooterLinksBlock,
    LandingFooterStampWrapper,
    LandingFooterStamp,
    LandingFooterDocumentsNavigation,
    LandingFooterDocumentLink,
    LandingFooterContactsBlock,
    LandingFooterContactTelegramBlock,
    LandingFooterTelegramIcon,
    LandingFooterTelegramTitle,
    LandingFooterContactEmailBlock,
    LandingFooterEmailIcon,
    LandingFooterEmailTitle,
    LandingFooterLogoLink,
    LandingFooterLogo,
    LandingFooterCommercialPagesGrid,
    LandingFooterCommercialPagesLink,
    LandingFooterMobileWrapper
};