import { FC, useEffect, useState } from "react";
import {
    PasswordRecoveryModalContent,
    PasswordRecoveryModalTitle,
    PasswordRecoveryModalInstruction,
    PasswordRecoveryModalInputBlock,
    PasswordRecoveryModalInputLabel,
    PasswordRecoveryModalInputComponent,
    PasswordRecoveryModalInputBackgroundLayer,
    PasswordRecoveryModalInputField,
    PasswordRecoveryModalInputActiveField,
    PasswordRecoveryModalMainButton,
    PasswordRecoveryModalBottomError,
    PasswordRecoveryModalInputError,
} from "./PasswordRecoveryModalStyles";
import { 
    ModalOutsideClose, 
    ModalExternalBlock, 
    ModalBackgroundLayer 
} from "components/Modals/ModalBackground/ModalBackgroundStyles";
import { ModalCloseComponent } from "components/ModalCloseComponent/ModalCloseComponent";
import FocusTrap from "focus-trap-react";
import validateEmail from "utils/validateEmail";
import axios from "axios";

interface IPasswordRecoveryModal {
    onClose: () => void;
    openMessModal(): any;
    setMessage(text: string): any;
}

const PasswordRecoveryModal: FC<IPasswordRecoveryModal> =  ({onClose, openMessModal, setMessage}) => {
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailInput, setEmailInput] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>(null);

    useEffect(() => {
        axios.get("/api/users/current")
        .then((res) => {
            if (res.data.email) {
                setEmailInput(res.data.email);
            }
        })
    }, []);

    useEffect(() => {
        if (emailInput) {
            if (!validateEmail(emailInput)) {
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }
        if (emailInput === ""){
            setEmailError(false);
        }
    }, [emailInput]);

    const handleRestoreRequest = (e: React.FormEvent<HTMLFormElement>):void => {
        e.preventDefault();
        axios.post("/api/users/restore/link", {
            email:emailInput.trim()
        })
        .then(() => {
            setMessage("Ссылка для смены пароля отправлена на почту");
            openMessModal();
            onClose();
        })
        .catch((err) => {
            if (err.response.status === 400) {
                setErrorMessage(err.response.data);
            } else if (err.response.status === 503) {
                setErrorMessage("Превышен лимит частоты для отправки запросов, попробуйте снова позже")
            } else {
                if (err.response.data.text) {
                    setErrorMessage(err.response.data.text);
                }
            }
        })
    }
    
    return (
        <FocusTrap focusTrapOptions={{ initialFocus: false, clickOutsideDeactivates: true }}>
            <ModalExternalBlock>
                <ModalOutsideClose onClick={onClose}></ModalOutsideClose>
                <PasswordRecoveryModalContent>
                    <ModalBackgroundLayer>
                        <PasswordRecoveryModalTitle>Смена пароля</PasswordRecoveryModalTitle>
                        <PasswordRecoveryModalInstruction>
                            Введите свой email указанный при регистрации
                        </PasswordRecoveryModalInstruction>
                        <form onSubmit={(e) => { handleRestoreRequest(e); }}>
                            <PasswordRecoveryModalInputBlock>
                                <PasswordRecoveryModalInputLabel htmlFor="email_input">
                                    Email
                                </PasswordRecoveryModalInputLabel>
                                <PasswordRecoveryModalInputComponent>
                                    <PasswordRecoveryModalInputBackgroundLayer>
                                        <PasswordRecoveryModalInputField 
                                            id="email_input" 
                                            type="email"
                                            value={emailInput}
                                            onChange={(e) => {setEmailInput(e.target.value)}} 
                                        />
                                        <PasswordRecoveryModalInputActiveField />
                                    </PasswordRecoveryModalInputBackgroundLayer>
                                </PasswordRecoveryModalInputComponent>
                                {emailError === true && (
                                    <PasswordRecoveryModalInputError>
                                        Неверный формат email
                                    </PasswordRecoveryModalInputError>
                                )}
                            </PasswordRecoveryModalInputBlock>
                            <PasswordRecoveryModalMainButton 
                                disabled={!emailInput || emailError} 
                                type="submit"
                            >
                                Далее
                            </PasswordRecoveryModalMainButton>
                        </form>
                        {errorMessage && (
                            <PasswordRecoveryModalBottomError>
                                {errorMessage}
                            </PasswordRecoveryModalBottomError>
                        )}
                    </ModalBackgroundLayer>
                    <ModalCloseComponent onClose={onClose} />
                </PasswordRecoveryModalContent>
            </ModalExternalBlock>
        </FocusTrap>
    );
}

export default PasswordRecoveryModal;