import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const MessageModalContent = styled.div`
  display: flex;
  width: ${pxIntoRem(450)};
  z-index: 9999;
  position: relative;
  padding: 1px;
  border-radius: ${pxIntoRem(12)};
  background: linear-gradient(180deg, rgba(26, 27, 37, 1) 5.42%, rgba(23, 24, 40, 1) 101.71%);
  @media (max-width: 500px) {
    width: calc(100% - ${pxIntoRem(30)});
    height: auto;
  }
`;

const MessageModalBackgroundLayer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${pxIntoRem(12)};
  background: #16161F;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const MessageModalIcon = styled.img`
  width: ${pxIntoRem(90)};
  height: ${pxIntoRem(90)};
  margin-top: ${pxIntoRem(40)};
`;

const MessageModalTitle = styled.h1`
  position: relative;
  text-align: center;
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(24)};
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  margin: ${pxIntoRem(10)} ${pxIntoRem(32)} auto ${pxIntoRem(32)};
  @media (max-width: 500px) {
    font-size: ${pxIntoRem(18)};
    margin: ${pxIntoRem(10)} ${pxIntoRem(20)} auto ${pxIntoRem(20)};
  }
`;

const MessageModalText = styled.p`
  position: relative;
  color: #79768B;
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: ${pxIntoRem(16)};
  span {
    color: #FFF;
  }
  @media (max-width: 500px) {
    margin-left: ${pxIntoRem(20)};
    margin-right: ${pxIntoRem(20)};
    width: auto;
  }
`;

const MessageModalMainButton = styled.button`
  display: flex;
  position: relative;
  width: ${pxIntoRem(386)};
  height: ${pxIntoRem(42)};
  padding: ${pxIntoRem(10)} ${pxIntoRem(50)};
  justify-content: center;
  align-items: center;
  border-radius: ${pxIntoRem(8)};
  background: #1683E2;
  margin: ${pxIntoRem(24)} ${pxIntoRem(32)} ${pxIntoRem(32)} ${pxIntoRem(32)};
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s;
  &:disabled {
    border: 1px solid #2D3042;
    color: #2D3042;
    background: transparent;
    pointer-events: none;
  }
  @media (min-width: 501px) {
    &:hover {
      background: #1668E2;
      box-shadow: 0px 0px ${pxIntoRem(24)} 0px rgba(22, 104, 226, 0.50);
      transition: 0.3s;
    }
    &:focus-visible {
      background: #1668E2;
      box-shadow: 0px 0px ${pxIntoRem(24)} 0px rgba(22, 104, 226, 0.50);
      transition: 0.3s;
    }
  }
  @media (max-width: 500px) {
    margin: ${pxIntoRem(24)} ${pxIntoRem(20)} ${pxIntoRem(20)} ${pxIntoRem(20)};
    width: calc(100% - ${pxIntoRem(40)});
  }
`;

export {
    MessageModalContent,
    MessageModalBackgroundLayer,
    MessageModalIcon,
    MessageModalTitle,
    MessageModalText,
    MessageModalMainButton
};