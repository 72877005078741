import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const fileScrollSlice = createSlice({
    name: 'fileScroll',
    initialState: {
        value: null
    },
    reducers: {
        setFileScrollSlice: (state, action: PayloadAction<number>) => {
            state.value = action.payload;
        }
    }
})

export const { setFileScrollSlice } = fileScrollSlice.actions;

export default fileScrollSlice.reducer;