import { useState } from "react";
import { 
    IndexCallToActionBackgroundBlock,
    IndexCallToActionBackgroundImage,
    IndexCallToAction,
    IndexCallToActionContent,
    IndexCallToActionBody,
    IndexCallToActionContentTitle,
    IndexCallToActionContentText,
    IndexCallToActionLogoBlock,
    IndexCallToActionMainButton
} from "./IndexCallToActionStyles";
import useModal from "hooks/useModal";
import RegistrationModal from "components/Modals/RegistrationModal/RegistrationModal";
import PasswordRecoveryModal from "components/Modals/PasswordRecoveryModal/PasswordRecoveryModal";
import MessageModal from "components/Modals/MessageModal/MessageModal";

const IndexCallToActionComponent = () => {
    const [message, setMessage] = useState<string>(null);

    const {
        openModal: openMessModal,
        modal: messageModal
    } = useModal(MessageModal, { modalType: "message_modal", message });
    const {
        openModal: openRecModal,
        modal: passwordRecoveryModal
    } = useModal(PasswordRecoveryModal, { openMessModal, setMessage });
    const {
        openModal: openRegModal,
        modal: registrationModal
    } = useModal(RegistrationModal, { openRecModal, openMessModal, modalType: "registration", setMessage });

    return (
        <IndexCallToActionBackgroundBlock>
                <IndexCallToActionBackgroundImage 
                    alt="background"
                    src="/images/bottom-pattern.webp"
                />
                <IndexCallToActionContent>
                    <IndexCallToAction>
                        <IndexCallToActionBody>
                            <IndexCallToActionLogoBlock alt="logo" src="/images/flash-logo.svg" />
                            <IndexCallToActionContentTitle>
                                Оцените качество расшифровки
                            </IndexCallToActionContentTitle>
                            <IndexCallToActionContentText>
                                Пройдите регистрацию и получите тестовые минуты, <br />которые можно использовать для перевода аудио или видео в текст бесплатно!
                            </IndexCallToActionContentText>
                            <IndexCallToActionMainButton id="bottom_button" type="button" onClick={openRegModal}>
                                Попробовать бесплатно
                            </IndexCallToActionMainButton>
                        </IndexCallToActionBody>
                    </IndexCallToAction>
                </IndexCallToActionContent>
                {registrationModal}
                {passwordRecoveryModal}
                {messageModal}
        </IndexCallToActionBackgroundBlock>
    );
}

export default IndexCallToActionComponent;