import { FC, useState, useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router";
import {
    IndexMiniFaqBlock,
    IndexMiniFaqBackgroundBlock,
    IndexMiniFaqBackgroundPicture,
    IndexMiniFaqUpperBackgroundImage,
    IndexMiniFaqTitle,
    IndexMiniFaqQuestionsBlock,
    IndexMiniFaqQuestionElementIcon,
    IndexMiniFaqQuestionElement,
    IndexMiniFaqQuestionElementBackgroundLayer,
    IndexMiniFaqQuestionElementTitle,
    IndexMiniFaqQuestionElementText,
    IndexMiniFaqQuestionElementInnerLink,
    IndexMiniFaqQuestionElementList
} from "./IndexMiniFaqStyles";
import { miniFaq } from "content/MiniFaqContent";

interface IFaqContent {
    question: string;
    text1st?: string;
    linkName?: string;
    href?: string;
    moreText?: string;
    list1st?: string;
    list2nd?: string;
    list3rd?: string;
    list4th?: string;
    list5th?: string;
}

interface IMiniFaqItem {
    item: IFaqContent;
}

const IndexMiniFaqPageItem: FC<IMiniFaqItem> = ({item}) => {
    const [isOpen, setIsOpen] = useState(false);

    useLayoutEffect(() => {
        setIsOpen(false);
    }, [item]);
    
    return (
        <IndexMiniFaqQuestionElement onClick={() => setIsOpen((prev) => !prev)}>
            <IndexMiniFaqQuestionElementBackgroundLayer />
            <IndexMiniFaqQuestionElementIcon width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="32" height="32" rx="8" />
                <path d="M17 11.1665V22.8332M11.1667 16.9998H22.8333" strokeLinecap="round" strokeLinejoin="round"/>
            </IndexMiniFaqQuestionElementIcon>
            <IndexMiniFaqQuestionElementTitle>{item.question}</IndexMiniFaqQuestionElementTitle>
            <IndexMiniFaqQuestionElementText className={isOpen === true ? "visible_text" : ""}>
                {item.text1st}
                {item.list1st && 
                    <IndexMiniFaqQuestionElementList>{item.list1st}</IndexMiniFaqQuestionElementList>}
                {item.list2nd && 
                    <IndexMiniFaqQuestionElementList>{item.list2nd}</IndexMiniFaqQuestionElementList>}
                {item.list3rd && 
                    <IndexMiniFaqQuestionElementList>{item.list3rd}</IndexMiniFaqQuestionElementList>}
                {item.list4th && 
                    <IndexMiniFaqQuestionElementList>{item.list4th}</IndexMiniFaqQuestionElementList>}
                {item.list5th && 
                    <IndexMiniFaqQuestionElementList className="last_list_element">
                        {item.list5th}
                    </IndexMiniFaqQuestionElementList>}
                {item.moreText}
                {item.linkName && (<IndexMiniFaqQuestionElementInnerLink to={item.href}>
                    {item.linkName}
                </IndexMiniFaqQuestionElementInnerLink>)}
            </IndexMiniFaqQuestionElementText>
        </IndexMiniFaqQuestionElement>
    );
}

const IndexMiniFaq = () => {
    const [content, setContent] = useState<IFaqContent[]>(null);
    const location = useLocation();

    useEffect(() => {
        const content = miniFaq[location.pathname];
        setContent(content);
    },[location])
    
    if (content) {
        return (
            <IndexMiniFaqBlock>
                <IndexMiniFaqBackgroundBlock>
                    <IndexMiniFaqBackgroundPicture>
                        <source media="(max-width:500px)" srcSet="/images/faq-upper-bg-mobile.svg" />
                        <IndexMiniFaqUpperBackgroundImage alt="background" src="/images/faq-upper-bg.svg" />
                    </IndexMiniFaqBackgroundPicture>
                    <IndexMiniFaqTitle>FAQ</IndexMiniFaqTitle>
                    <IndexMiniFaqQuestionsBlock>
                        {content.map((item, idx) => {
                            return (
                                <IndexMiniFaqPageItem key={idx} item={item} />
                            );
                        })}
                    </IndexMiniFaqQuestionsBlock>
                </IndexMiniFaqBackgroundBlock>
            </IndexMiniFaqBlock>
        );
    }
}

export default IndexMiniFaq;