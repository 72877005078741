export const helmetContent = {
    "/": {
        title: "Аудио в текст онлайн - перевод аудио | Semantix.one",
        description: "Перевод аудио в текст онлайн. Транскрибируйте аудиозапись в текст в пару кликов. Высокая скорость и максимальная точность расшифровки. Поддержка разных форматов аудио для перевода в текст. Транскрибация интервью, лекций, записей телефонных разговоров."
    },
    "/convert-audio2text": {
        title: "Нейросеть для перевода аудио в текст - расшифровка аудио в текст | Semantix.one",
        description: "Переведите текст в речь с помощью нейросети. Точность расшифровки — 95%. Нейросеть для перевода аудио в текст обрабатывает файлы за несколько минут, расставляет знаки препинания. Протестируйте сервис на базе ИИ бесплатно."
    },
    "/video2text": {
        title: "Перевод видео в текст онлайн - транскрибация видео | Semantix.one",
        description: "Перевод видео в текст онлайн с помощью технологии искусственного интеллекта. Автоматическая транскрибация видео в текст в пару кликов и за несколько минут. Поддержка популярных форматов видео и создание субтитров для Youtube."
    },
    "/convert-video2text": {
        title: "Нейросеть для транскрибации видео в текст | Semantix.one",
        description: "Нейросеть для транскрибации любых видео в текст. Перевод файлов самых популярных форматов. Высокое качество расшифровки и результат за несколько минут. Протестируйте сервис бесплатно в пару кликов."
    },
    "/generate-subtitles": {
        title: "Генератор субтитров - создание субтитров для видео | Semantix.one",
        description: "Перевод аудио в текст онлайн. Транскрибируйте аудиозапись в текст в пару кликов. Высокая скорость и максимальная точность расшифровки. Поддержка разных форматов аудио для перевода в текст. Транскрибация интервью, лекций, записей телефонных разговоров."
    },
    "/convert-court-audiofiles": {
        title: "Расшифровка аудиозаписи для суда - стенограмма судебного заседания | Semantix.one",
        description: "Расшифровка диктофонных аудиозаписей, а также видеозаписей для суда. Качество транскрибации — 95%. Расшифровка допросов, свидетельских показаний, улик, заседаний суда. Гарантируем безопасность информации и полную конфиденциальность."
    },
    "/audio-video2text": {
        title: "Расшифровка аудио и видео в текст онлайн | Semantix.one",
        description: "Транскрибация аудио и видео в текст онлайн с помощью искусственного интеллекта. Извлечение текста из видео и аудио в пару кликов и за несколько минут. Сервис расставит тайм-коды, знаки препинания, сможет различить разные голоса."
    },
    "/mp4totext": {
        title: "Преобразование MP4 в текст онлайн | Semantix.one",
        description: "Онлайн-конвертер для перевода MP4 в текст без установки программы на компьютер. Преобразуйте аудио и видео с помощью технологии искусственного интеллекта. Сервис быстро конвертирует MP4 в текст с точностью до 95%."
    },
    "/mp3totext": {
        title: "Перевод mp3 в текст онлайн | Semantix.one",
        description: "Расшифруйте MP3 в текст с помощью технологий искусственного интеллекта. Автоматическая транскрибация аудио в несколько кликов. Точность перевода MP3 в текст — 95%. Бесплатный тестовый период."
    },
    "/convert-interviews": {
        title: "Расшифровка интервью онлайн | Semantix.one",
        description: "Транскрибирование интервью с помощью технологий искусственного интеллекта. Автоматическая расшифровка записи в пару кликов с точностью 95%. Поддержка разных форматов аудио и видео. Бесплатная расшифровка интервью онлайн."
    }
};