import styled from "styled-components";
import pxIntoRem from "utils/pxIntoRem";

const ModalOutsideClose = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

const ModalExternalBlock = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.70);
`;

const ModalBackgroundLayer = styled.div`
  border-radius: ${pxIntoRem(12)};
  background: #16161F;
  width: 100%;
  height: 100%;
`;

export {
    ModalOutsideClose,
    ModalExternalBlock,
    ModalBackgroundLayer,
};