import styled from "styled-components";
import pxIntoRem from "utils/pxIntoRem";

const ModalCloseButton = styled.button`
  position: absolute;
  width: ${pxIntoRem(24)};
  height: ${pxIntoRem(24)};
  top: ${pxIntoRem(16)};
  right: ${pxIntoRem(16)};
  background: transparent;
  justify-content: center;
  align-items: center;
  z-index: 999;
  &:focus-visible {
    border-radius: ${pxIntoRem(4)};
    outline: 1px solid rgba(22, 131, 226, 1);
    outline-offset: -1px;
  }
`;

const ModalCloseButtonIcon = styled.svg`
  width: ${pxIntoRem(24)};
  height: ${pxIntoRem(24)};
  stroke: #79768B;
  transition: 0.3s;
  @media (min-width: 501px) {
    &:hover {
      stroke: #FFF;
    }
  }
`;

export {
  ModalCloseButton,
  ModalCloseButtonIcon
};