import { FC, SyntheticEvent, useEffect, useState } from "react";
import {
    RegistrationModalContent,
    RegistrationModalTitle,
    RegistrationModalInputBlock,
    RegistrationModalInputLabel,
    RegistrationModalInputLabelRowWrapper,
    RegistrationModalInputComponent,
    RegistrationModalInputBackgroundLayer,
    RegistrationModalInputField,
    RegistrationModalInputActiveField,
    RegistrationModalCheckboxBlock,
    RegistrationModalCheckboxInput,
    RegistrationModalCheckboxText,
    RegistrationModalCheckboxLink,
    RegistrationModalMainButton,
    RegistrationModalLoginLink,
    RegistrationModalLoginButton,
    RegistrationModalInputButton,
    RegistrationModalInputError,
    RegistrationModalPasswordStrength,
    RegistrationModalShowPassword,
    RegistrationModalTooltipButton,
    RegistrationModalTooltipBlock,
    RegistrationModalTooltipBlockText,
    RegistrationModalOtpBlock,
    RegistrationModalSendOtpButton
} from "./RegistrationModalStyles";
import {
    LoginModalContent,
    LoginModalTitle,
    LoginModalInputBlock,
    LoginModalInputLabel,
    LoginModalInputComponent,
    LoginModalInputBackgroundLayer,
    LoginModalInputField,
    LoginModalInputActiveField,
    LoginModalMainButton,
    LoginModalLoginLink,
    LoginModalLoginLinkButton,
    LoginModalForgotPasswordButtonBlock,
    LoginModalForgotPasswordButton,
    LoginModalInputButton,
    LoginModalBottomError
} from "./LoginModalStyles";
import { 
    ModalOutsideClose, 
    ModalExternalBlock, 
    ModalBackgroundLayer 
} from "components/Modals/ModalBackground/ModalBackgroundStyles";
import { ModalCloseComponent } from "components/ModalCloseComponent/ModalCloseComponent";
import { TooltipIconSVG } from "components/SvgComponents/TooltipIconSVG";
import { InputButtonIconSVG } from "components/SvgComponents/InputButtonIconSVG";
import validateEmail from "utils/validateEmail";
import axios from "axios";
import FocusTrap from "focus-trap-react";

interface IRegistrationModal {
    onClose: () => void;
    openRecModal(): any;
    openMessModal(): any;
    setMessage(text: string): any;
    modalType: string;
}

const RegistrationModal: FC<IRegistrationModal> =  ({onClose, openRecModal, openMessModal, setMessage, modalType}) => {
    const [tooltipActive, setTooltipActive] = useState<boolean>(false);
    const [activeFirstButton, setActiveFirstButton] = useState<boolean>(false);
    const [activeSecondButton, setActiveSecondButton] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [otpInput, setOtpInput] = useState<string>(null);
    const [otpButtonState, setOtpButtonState] = useState<string>("Запросить код");
    const [otpDelayTimer, setOtpDelayTimer] = useState<number>(null);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [registrationButtonDisabled, setRegistrationButtonDisabled] = useState<boolean>(true);
    const [checkboxActive, setCheckboxActive] = useState<boolean>(false);
    const [modalTypeState, setModalTypeState] = useState<string>(modalType);
    const [loginInput, setLoginInput] = useState<string>(null);
    const [emailInput, setEmailInput] = useState<string>(null);
    const [passwordInput, setPasswordInput] = useState<string>(null);
    const [passwordRepeatInput, setPasswordRepeatInput] = useState<string>(null);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [passwordStrength, setPasswordStrength] = useState<string>(null);
    const [key, setKey] = useState<number>(0);

    useEffect(() => {
        if (otpDelayTimer && otpDelayTimer > 0) {
            const timeout = setTimeout(() => {
                setOtpDelayTimer(otpDelayTimer - 1);
                setOtpButtonState(`Новый код через ${otpDelayTimer - 1}c.`);
            }, 1000);
            return () => clearInterval(timeout);
        } else if (otpDelayTimer === 0) {
            setOtpDelayTimer(null);
            setOtpButtonState("Запросить код");
        }
    }, [otpDelayTimer]);

    useEffect(() => {
        if (passwordInput && passwordRepeatInput) {
            if (passwordInput !== passwordRepeatInput) {
                setPasswordError(true);
            } else if (passwordInput === passwordRepeatInput) {
                setPasswordError(false);
            }
        }
        if (!passwordRepeatInput || !passwordInput) {
            setPasswordError(false);
            setPasswordStrength(null);
        }
        if (passwordInput) {
            const exp = RegExp(
                ".*([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+).*"
            );
            if (!exp.test(passwordInput) || passwordInput.trim().length < 8 || passwordInput.trim().length > 50) {
                setPasswordStrength("Слабый");
            } else if (exp.test(passwordInput) && passwordInput.trim().length >= 8 && passwordInput.trim().length <= 50) {
                setPasswordStrength("Сильный");
            }
        }
    },[passwordInput, passwordRepeatInput, emailInput, otpInput]);

    useEffect(() => {
        if (loginInput && emailInput && otpInput && passwordInput && passwordRepeatInput
            && !emailError && !passwordError && checkboxActive && passwordStrength === "Сильный") {
                setRegistrationButtonDisabled(false);
            } else {
                setRegistrationButtonDisabled(true);
            }
    }, [loginInput, emailInput, otpInput, passwordInput, passwordRepeatInput, emailError, passwordError, checkboxActive]);

    useEffect(() => {
        const input = document.getElementById("email_input") as HTMLInputElement;
        if (input) {
            setTimeout(() => {
                if (input.matches(':autofill')) {
                    handleValidateEmail(input.value);
                }
            }, 100);
        }
    }, [emailInput]);

    useEffect(() => {
        setActiveFirstButton(false);
        setActiveSecondButton(false);
    }, [modalTypeState]);
    
    const togglePasswordVisibility = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const button = e.target as Element;
        if (button.classList.contains("button_second") == true) {
            const elem = document.querySelector("#psswd_repeat");
            const type = elem.getAttribute('type') === 'password' ? 'text' : 'password';
            elem.setAttribute('type', type);
            setActiveSecondButton(current => !current);
        } else {
            const elem = document.querySelector("#psswd_input");
            const type = elem.getAttribute('type') === 'password' ? 'text' : 'password';
            elem.setAttribute('type', type);
            setActiveFirstButton(current => !current);
        }
    }

    const handleValidateEmail = (email: string): void => {
        if (!validateEmail(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    const toggleModals = (): void => {
        setPasswordInput(null);
        setPasswordStrength(null);
        setErrorMessage(null);
        if (modalTypeState === "registration") {
            handleOpenLoginModal();
            setKey(1);
        } else if (modalTypeState === "login") {
            setModalTypeState("registration");
            setKey(2);
        }
    }

    const handleRegistration = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setErrorMessage(null);
        if (passwordError === false && emailError === false) {
            axios.post("/api/users/reg", {
                login: loginInput,
                email: emailInput,
                password: passwordInput,
                code: Number(otpInput)
            })
            .then(() => {
                onClose();
                setMessage("Регистрация прошла успешно!");
                openMessModal();
            })
            .catch((err) => {
                if (err.response.status) {
                    if (err.response.status === 403 || err.response.status === 401) {
                        window.location.href = "/#login";
                        localStorage.removeItem("userData");
                    } else if (err.response.status === 503) {
                        setErrorMessage("Превышен лимит частоты для отправки запросов, попробуйте снова позже")
                    } else if (err.response.status === 409) {
                        const timer = err.response.data.seconds;
                        if (timer) {
                            setOtpDelayTimer(timer);
                        }
                        setOtpButtonState(`Новый код через ${timer}c.`);
                        setErrorMessage(err.response.data.text);
                    } else {
                        setErrorMessage(err.response.data);
                    }
                }
            })
        }
    }

    const handleAuth = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setErrorMessage(null);
        if (loginInput && passwordInput) {
            const login = loginInput.trim();
            const psswd = passwordInput.trim();
            axios.post("/api/auth", {
                login: login,
                password: psswd
            })
            .then(() => {
                axios.get("/api/users/current")
                .then(res => {
                    if (res.data.hasOwnProperty("login")) {
                        localStorage.setItem("userData", JSON.stringify(res.data));
                    }
                    window.location.href = "/app/main";
                })
                .catch(err => {
                    if (err.response.status) {
                        if (err.response.status === 403 || err.response.status === 401) {
                            window.location.href = "/#login";
                            localStorage.removeItem("userData");
                        } else if (err.response.status === 500) {
                            setErrorMessage("Ошибка сервера.");
                        }
                    }
                })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    setErrorMessage("Неверный логин или пароль");
                } else if (err.response.status === 500) {
                    setErrorMessage("Ошибка сервера.");
                } else if (err.response.status === 503) {
                    setErrorMessage("Превышен лимит частоты для отправки запросов, попробуйте снова позже")
                }
            })
        }
    }

    const handleOpenLoginModal = (): void => {
        setErrorMessage(null);
        setModalTypeState("login");
    }

    const showTipOnClick = (): void => {
        setTooltipActive(true);
        setTimeout(() => {
            hideTip();
        }, 5000);
    };

    const showTip = (): void => {
        setTooltipActive(true);
    };
    
    const hideTip = (): void => {
        setTooltipActive(false);
    };

    const handleCloseModal = (): void => {
        onClose();
        if (window.location.href.indexOf("#login") > -1) {
            window.history.pushState("", document.title, window.location.pathname);
        }
    }

    const handleOtpButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        setErrorMessage(null);
        axios.post("/api/users/reg/code", {
            login: loginInput,
            password: passwordInput,
            email: emailInput,
            code: 0
        })
        .then((res) => {
            setOtpButtonState("Код отправлен");
        })
        .catch((err) => {
            if (err.response.status) {
                if (err.response.status === 403 || err.response.status === 401) {
                    window.location.href = "/#login";
                    localStorage.removeItem("userData");
                }
                if (err.response.status === 409) {
                    const timer = err.response.data.seconds;
                    if (timer) {
                        setOtpDelayTimer(timer);
                    }
                    setOtpButtonState(`Новый код через ${timer}c.`);
                    setErrorMessage("Код на указанный email уже отправлен");
                } else if (err.response.status === 400) {
                    setErrorMessage(err.response.data);
                } else if (err.response.status === 503) {
                    setErrorMessage("Превышен лимит частоты для отправки запросов, попробуйте снова позже");
                }
            }
        })
    }

    if (modalTypeState === "registration") {
        return (
            <FocusTrap key={key} focusTrapOptions={{ initialFocus: false, clickOutsideDeactivates: true }}>
                <ModalExternalBlock>
                    <ModalOutsideClose onClick={handleCloseModal}></ModalOutsideClose>
                    <RegistrationModalContent>
                        {tooltipActive === true && (
                            <RegistrationModalTooltipBlock>
                                <RegistrationModalTooltipBlockText>
                                    Пароль должен быть длиной от 8 до 50 символов, содержать одну заглавную, одну прописную букву и цифру. Все символы должны быть латинскими
                                </RegistrationModalTooltipBlockText>
                            </RegistrationModalTooltipBlock>
                        )}
                        <ModalBackgroundLayer>
                            <form onSubmit={(e) => { handleRegistration(e); }}>
                                <RegistrationModalTitle>Регистрация</RegistrationModalTitle>
                                <RegistrationModalInputBlock>
                                    <RegistrationModalInputLabel htmlFor="username_input">
                                        Имя пользователя
                                    </RegistrationModalInputLabel>
                                    <RegistrationModalInputComponent 
                                        className={errorMessage === "Имя пользователя уже используется" ? "error" : ""}
                                    >
                                        <RegistrationModalInputBackgroundLayer>
                                            <RegistrationModalInputField
                                                id="username_input"
                                                type="text" 
                                                onChange={(e) => { setLoginInput(e.target.value); }} 
                                            />
                                            <RegistrationModalInputActiveField />
                                        </RegistrationModalInputBackgroundLayer>
                                    </RegistrationModalInputComponent>
                                </RegistrationModalInputBlock>
                                <RegistrationModalInputBlock>
                                    <RegistrationModalInputLabel htmlFor="email_input">Email</RegistrationModalInputLabel>
                                    <RegistrationModalInputComponent className={!emailInput && passwordInput ? "error" : ""}>
                                        <RegistrationModalInputBackgroundLayer>
                                            <RegistrationModalInputField
                                                id="email_input"
                                                type="email"
                                                autoComplete="email"
                                                onChange={(e) => { setEmailInput(e.target.value); 
                                                    handleValidateEmail(e.target.value); 
                                                }}
                                            />
                                            <RegistrationModalInputActiveField />
                                        </RegistrationModalInputBackgroundLayer>
                                    </RegistrationModalInputComponent>
                                    {emailError === true && (
                                        <RegistrationModalInputError>
                                            Неверный формат e-mail
                                        </RegistrationModalInputError>
                                    )}
                                </RegistrationModalInputBlock>
                                <RegistrationModalInputBlock>
                                    <RegistrationModalInputLabel htmlFor="email_otp">
                                        Код проверки
                                    </RegistrationModalInputLabel>
                                    <RegistrationModalOtpBlock>
                                        <RegistrationModalInputComponent 
                                            className={errorMessage?.includes("Неверный код") ? "error" : ""}
                                        >
                                            <RegistrationModalInputBackgroundLayer>
                                                <RegistrationModalInputField
                                                    id="otp_input"
                                                    type="text" 
                                                    onChange={(e) => { setOtpInput(e.target.value); }} 
                                                />
                                                <RegistrationModalInputActiveField />
                                            </RegistrationModalInputBackgroundLayer>
                                        </RegistrationModalInputComponent>
                                        <RegistrationModalSendOtpButton 
                                            disabled={
                                                otpButtonState === "Запросить код" 
                                                  ? !emailInput || emailError 
                                                  : Boolean(otpDelayTimer)
                                            }
                                            className={otpButtonState && otpButtonState.includes("Новый код") ? "timer_button" : "" }
                                            onClick={(e) => { handleOtpButton(e); }}
                                        >
                                            {otpButtonState}
                                        </RegistrationModalSendOtpButton>
                                    </RegistrationModalOtpBlock>
                                </RegistrationModalInputBlock>
                                <RegistrationModalInputBlock>
                                    <RegistrationModalInputLabelRowWrapper>
                                        <RegistrationModalInputLabel htmlFor="psswd_input">Пароль</RegistrationModalInputLabel>
                                        <RegistrationModalPasswordStrength 
                                            className={passwordStrength === "Сильный" ? "pass_strong" : ""}
                                        >
                                            {passwordStrength}
                                        </RegistrationModalPasswordStrength>
                                        {passwordStrength && (
                                            <RegistrationModalTooltipButton 
                                                type="button"
                                                onMouseEnter={showTip} 
                                                onMouseLeave={hideTip}
                                                onClick={(e) => { e.preventDefault(); showTipOnClick(); }}
                                            >
                                                <TooltipIconSVG />
                                            </RegistrationModalTooltipButton>
                                        )}
                                    </RegistrationModalInputLabelRowWrapper>
                                    <RegistrationModalInputComponent>
                                        <RegistrationModalInputBackgroundLayer>
                                            <RegistrationModalInputField 
                                                id="psswd_input" 
                                                type="password" 
                                                autoComplete="new-password"
                                                onChange={(e) => { setPasswordInput(e.target.value); }}
                                            />
                                            <RegistrationModalInputActiveField />
                                            <RegistrationModalInputButton onClick={togglePasswordVisibility} type="button">
                                                <InputButtonIconSVG active={activeFirstButton} />
                                            </RegistrationModalInputButton>
                                            <RegistrationModalShowPassword>
                                                Показать пароль
                                            </RegistrationModalShowPassword>
                                        </RegistrationModalInputBackgroundLayer>
                                    </RegistrationModalInputComponent>
                                </RegistrationModalInputBlock>
                                <RegistrationModalInputBlock>
                                    <RegistrationModalInputLabelRowWrapper>
                                        <RegistrationModalInputLabel htmlFor="psswd_repeat">Повторите пароль</RegistrationModalInputLabel>
                                        {passwordInput && passwordRepeatInput && (
                                            <RegistrationModalPasswordStrength 
                                                className={passwordError ? "pass_not_match" : "pass_match"}
                                            >
                                                {passwordError ? "Не совпадает" : "Совпадает"} 
                                            </RegistrationModalPasswordStrength>
                                        )}
                                    </RegistrationModalInputLabelRowWrapper>
                                    <RegistrationModalInputComponent>
                                        <RegistrationModalInputBackgroundLayer>
                                            <RegistrationModalInputField 
                                                id="psswd_repeat" 
                                                type="password"
                                                autoComplete="new-password"
                                                onChange={(e) => { setPasswordRepeatInput(e.target.value); }} 
                                            />
                                            <RegistrationModalInputActiveField />
                                            <RegistrationModalInputButton 
                                                type="button"
                                                className="button_second" 
                                                onClick={togglePasswordVisibility}
                                            >
                                                <InputButtonIconSVG active={activeSecondButton} />
                                            </RegistrationModalInputButton>
                                            <RegistrationModalShowPassword>
                                                Показать пароль
                                            </RegistrationModalShowPassword>
                                        </RegistrationModalInputBackgroundLayer>
                                    </RegistrationModalInputComponent>
                                </RegistrationModalInputBlock>
                                <label>
                                    <RegistrationModalCheckboxBlock>
                                        <RegistrationModalCheckboxInput 
                                            type="checkbox"
                                            onChange={() => { setCheckboxActive(current => !current); }}
                                        />
                                        <RegistrationModalCheckboxText>
                                            Я ознакомлен (-на) и согласен (-на) с
                                            <RegistrationModalCheckboxLink to="/offer" target="_blank" rel="noopener noreferrer">
                                                Публичной офертой
                                            </RegistrationModalCheckboxLink>
                                            и
                                            <RegistrationModalCheckboxLink to="/agreement" target="_blank" rel="noopener noreferrer">
                                                Политикой конфиденциальности
                                            </RegistrationModalCheckboxLink>
                                            , даю свое согласие на обработку моих персональных данных
                                        </RegistrationModalCheckboxText>
                                    </RegistrationModalCheckboxBlock>
                                </label>
                                <RegistrationModalMainButton
                                    disabled={registrationButtonDisabled}
                                    type="submit"
                                >
                                    Зарегистрироваться
                                </RegistrationModalMainButton>
                                {errorMessage && (
                                    <LoginModalBottomError>{errorMessage}</LoginModalBottomError>
                                )}
                                <RegistrationModalLoginLink>
                                    Уже есть аккаунт? 
                                    <RegistrationModalLoginButton type="button" onClick={toggleModals}>
                                        Войти
                                    </RegistrationModalLoginButton>
                                </RegistrationModalLoginLink>
                            </form>
                        </ModalBackgroundLayer>
                        <ModalCloseComponent onClose={handleCloseModal} />
                    </RegistrationModalContent>
                </ModalExternalBlock>
            </FocusTrap>
        );
    } else if (modalTypeState === "login") {
        return (
            <FocusTrap key={key} focusTrapOptions={{ initialFocus: false, clickOutsideDeactivates: true }}>
                <ModalExternalBlock>
                    <ModalOutsideClose onClick={handleCloseModal}></ModalOutsideClose>
                    <LoginModalContent>
                        <ModalBackgroundLayer>
                            <form onSubmit={(e) => { handleAuth(e); }}>
                                <LoginModalTitle>Войти в аккаунт</LoginModalTitle>
                                <LoginModalInputBlock>
                                    <LoginModalInputLabel htmlFor="username_input">
                                        Имя пользователя
                                        <LoginModalInputComponent>
                                            <LoginModalInputBackgroundLayer>
                                                <LoginModalInputField 
                                                    type="text"
                                                    id="username_input"
                                                    autoComplete="username"
                                                    onChange={(e) => { setLoginInput(e.target.value); }} 
                                                />
                                                <LoginModalInputActiveField />
                                            </LoginModalInputBackgroundLayer>
                                        </LoginModalInputComponent>
                                    </LoginModalInputLabel>
                                </LoginModalInputBlock>
                                <LoginModalInputBlock>
                                    <LoginModalInputLabel htmlFor="psswd_input">
                                        Пароль
                                        <LoginModalInputComponent>
                                            <LoginModalInputBackgroundLayer>
                                                <LoginModalInputField 
                                                    id="psswd_input" 
                                                    type="password"
                                                    autoComplete="current-password"
                                                    onChange={(e) => { setPasswordInput(e.target.value); }} 
                                                />
                                                <LoginModalInputActiveField />
                                                <LoginModalInputButton type="button" onClick={togglePasswordVisibility}>
                                                    <InputButtonIconSVG active={activeFirstButton} />
                                                </LoginModalInputButton>
                                                <RegistrationModalShowPassword>
                                                    Показать пароль
                                                </RegistrationModalShowPassword>
                                            </LoginModalInputBackgroundLayer>
                                        </LoginModalInputComponent>
                                    </LoginModalInputLabel>
                                </LoginModalInputBlock>
                                <LoginModalForgotPasswordButtonBlock>
                                    <LoginModalForgotPasswordButton 
                                        type="button" 
                                        onClick={() => { openRecModal(); handleCloseModal(); }}
                                    >
                                        Забыли пароль?
                                    </LoginModalForgotPasswordButton>
                                </LoginModalForgotPasswordButtonBlock>
                                <LoginModalMainButton 
                                    disabled={!loginInput || !passwordInput}
                                    type="submit"
                                >
                                    Войти
                                </LoginModalMainButton>
                            </form>
                            {errorMessage && (
                                <LoginModalBottomError>{errorMessage}</LoginModalBottomError>
                            )}
                            <LoginModalLoginLink>
                                Нет аккаунта? 
                                <LoginModalLoginLinkButton type="button" onClick={toggleModals}>
                                    Зарегистрироваться
                                </LoginModalLoginLinkButton>
                            </LoginModalLoginLink>
                        </ModalBackgroundLayer>
                        <ModalCloseComponent onClose={handleCloseModal} />
                    </LoginModalContent>
                </ModalExternalBlock>
            </FocusTrap>
        );
    }
}

export default RegistrationModal;