export const miniFaq = {
    "/": [
        {
            question: "Как получить текст или субтитры?",
            text1st: "Для использования конвертера выполните три простых действия: ",
            list1st: "Добавьте аудио или видео. Перетащите файл  в специальное окно или загрузите его в программу с помощью специальной кнопки.",
            list2nd: "Выберите дополнительные параметры. Например, добавьте функцию подавление шума.",
            list3rd: "Получите текст. При необходимости ее можно отредактировать прямо в генераторе онлайн.",
            list4th: "Скачайте расшифровку. Выберите подходящий формат и сохраните текст на компьютер."
        }, {
            question: "Как получить текст в Word?",
            text1st: "Загрузите аудио или видео, которое хотите транскрибировать. После того как инструмент преобразует речь в текст, выберите формат документа docx и скачайте результат на компьютер."
        }, {
            question: "Инструмент может создать субтитры для Youtube?",
            text1st: "Конвертер автоматически генерирует субтитры из видео. Чтобы вставить их на Youtube, скачайте текст в формате srt. Для удобства в файле будут временные метки."
        }, {
            question: "Насколько быстро конвертер переводит речь в текст?",
            text1st: "Чтобы автоматически расшифровать часовую запись, генератору потребуется примерно 10 минут. Чем короче аудио, тем быстрее сервис выполнит работу."
        }, {
            question: "Можно ли редактировать текст?",
            text1st: "Готовую расшифровку можно поправить прямо в браузере: исправить неточности, изменить формулировки."
        } 
    ],
    "/convert-audio2text": [
        {
            question: "Какие языки поддерживает сервис?",
            text1st: "Semantix может обрабатывать записи на русском, английском, испанском, немецком, итальянском языках. Наша команда работает над тем, чтобы расширить список языков."
        }, {
            question: "Как сделать расшифровку аудио?",
            list1st: "загрузите файл;",
            list2nd: "выберите дополнительные настройки;",
            list3rd: "запустите транскрибацию;",
            list4th: "отредактируйте текст;",
            list5th: "скачайте текст в удобном формате."
        }, {
            question: "Сколько хранятся файлы с исходниками?",
            text1st: "Аудиозапись хранится 30 дней, после чего удаляется. Этот срок можно увеличить на тарифе «Бизнес»."
        }, {
            question: "Что нужно сделать, если у меня закончились минуты для транскрибации?",
            text1st: "Вы можете заново оплатить тариф или докупить нужное количество минут."
        }, {
            question: "Сколько времени длится расшифровка аудио?",
            text1st: "Время зависит от длительности аудио. Так, на перевод часовой записи в текст обычно уходит 10 минут. Но в редких случаях, например, при большой нагрузке на сервера, может потребоваться больше времени."
        }, {
            question: "Сервис сможет расшифровать запись с низким качеством звука?",
            text1st: "Да, наш инструмент преобразует речь, даже если на записи есть шумы и посторонние звуки. Для этого перед транскрибацией нужно выбрать опции нормализации голоса и подавления шума."
        }
    ],
    "/video2text": [
        {
            question: "Как экспортировать субтитры на компьютер?",
            text1st: "Чтобы получить субтитры для использования в видео, при скачивании текста выберите формат srt. Сервис автоматически создаст нужный файл."
        }, {
            question: "Насколько точно получится конвертировать звук в текст?",
            text1st: "Semantix работает на базе технологии искусственного интеллекта (ИИ). Поэтому вы получите расшифровку высокого качества, даже если на видео есть посторонние звуки и шумы. Также можно воспользоваться дополнительными функциями инструмента, чтобы сделать текст еще «чище»."
        }, {
            question: "Кто может использовать сервис расшифровки видео онлайн?",
            text1st: "Наша программа подходит для использования в разных сферах. Например, она помогает:",
            list1st: "Создателям видео в TikTok, Youtube — добавлять субтитры для большего вовлечения зрителей.",
            list2nd: "Работникам киноиндустрии — за короткое время создавать субтитры к фильмам, мультфильмам, сериалам.",
            list3rd: "Студентам и преподавателям — транскрибировать аудио- и видеозаписи лекций, семинаров, выступлений в учебных целях.",
            list4th: "Людям, имеющим проблемы со слухом — получать доступ к информации, фильмам, видео.",
            moretext1st: "Воспользоваться программой может любой человек для работы, учебы или развлечений."
        }, {
            question: "Можно ли конвертировать аудио в текст?",
            text1st: "Разумеется! Вы можете получить текст из аудио таких форматов: M4A, MP3, OGG, AAC, WAV, FLAC, WMA. Процесс транскрибации звука такой же, как и расшифровка видео."
        }
    ],
    "/convert-video2text": [
        {
            question: "Насколько точно сервис расшифровывает видео и аудио?",
            text1st: "Точность транскрибирования достигает 95%. Но показатель может быть ниже, особенно если вы используете запись плохого качества. При необходимости можно редактировать готовый текст прямо в браузере."
        }, {
            question: "Как скачать субтитры для встраивания в видео?",
            text1st: "Экспортируйте документ в формате srt: это файл, содержащий субтитры с указанием времени. Если нужно внести изменения, воспользуйтесь режимом редактирования."
        }, {
            question: "Сколько времени видеофайл хранится на сервере?",
            text1st: "Вы можете загрузить видеоролик в наш генератор и использовать его в течение 30 дней. Срок хранения можно увеличить на тарифе «Бизнес»."
        }, {
            question: "Можно ли с помощью сервиса конвертировать аудио в текст?",
            text1st: "Разумеется! Наш онлайн-инструмент обрабатывает аудио таких форматов: M4A, MP3, OGG, AAC, WAV, FLAC, WMA."
        }, {
            question: "Конвертер сможет транскрибировать видео плохого качества?",
            text1st: "Да, но если на видео плохой звук или шумы, воспользуйтесь дополнительными опциями, чтобы автоматически улучшить качество речи. Так расшифровка будет более точная и грамотная."
        }
    ],
    "/generate-subtitles": [
        {
            question: "Что такое генератор субтитров?",
            text1st: "Генератор субтитров — это инструмент, который автоматически создает субтитры из видео с помощью технологий распознавания речи и искусственного интеллекта."
        }, {
            question: "Каким образом генератор создает субтитры?",
            text1st: "Инструмент анализирует файл, преобразует его в текст и синхронизирует с видео. Вы можете создать субтитры из аудио: чтобы было проще соединить их с роликом, инструмент расставляет временные отметки — тайм-коды."
        }, {
            question: "Инструмент поддерживает любые форматы видео?",
            text1st: "Наш генератор работает с видео таких форматов: MP4, MKV, FLV, AVI, MOV, WMV. Если в списке нет нужного вам разрешения, напишите нам: ",
            linkName: "hello@semantix.one",
            href: "mailto:hello@semantix.one"
        }, {
            question: "Субтитры, которые создает генератор, полностью точные?",
            text1st: "Автоматические субтитры почти полностью повторяют звук из видео, но они не могут быть на 100% идеальными. Поэтому мы рекомендуем воспользоваться онлайн-редактором и изменить текст прямо в браузере. Обычно требуются минимальные правки."
        }, {
            question: "Генератор может создать субтитры на разных языках?",
            text1st: "Да, Semantix поддерживает не только русский, но и английский, немецкий, испанский, итальянский языки."
        }, {
            question: "Сколько времени хранятся загруженные файлы?",
            text1st: "Файлы находятся в облаке 30 дней. Этот срок можно увеличить на тарифе «Бизнес»."
        }
    ],
    "/convert-court-audiofiles": [
        {
            question: "Как заказать услугу расшифровки?",
            text1st: "Чтобы заказать расшифровку аудио для суда, зарегистрируйтесь на сайте. Затем выполните такие действия:",
            list1st: "Отправьте файл с аудио в специальное поле.",
            list2nd: "Выберите параметры для улучшения качества расшифровки.",
            list3rd: "Начните транскрибацию, и сервис приступит к переводу записи в текст.",
            list4th: "Отредактируйте расшифровку и скачайте документ на компьютер.",
            moretext1st: "Если у вас возникли сложности с заказом или появились вопросы по услуге, напишите нам по адресу: ",
            linkName: "hello@semantix.one",
            href: "mailto:hello@semantix.one"
        }, {
            question: "Можно ли заказать расшифровку видеозаписи для суда?",
            text1st: "Разумеется! Вы можете заказать услугу перевода видео: для этого выполните те же действия, что и при расшифровке аудиозаписи. Стоимость при этом не меняется."
        }, {
            question: "Какова стоимость расшифровки?",
            text1st: "Цена услуги различается на разных тарифах и составляет от 1,5 рублей за одну минуту. "
        }, {
            question: "Расшифровка соответствует требованиям законодательства?",
            text1st: "Результат представляет собой текст с тайм-кодами и знаками препинания для удобного восприятия. Привести его в юридически грамотный вид вы можете сами прямо в браузере: для этого есть услуга редактирования, которая входит в стоимость заказа."
        }
    ],
    "/audio-video2text": [
        {
            question: "Как работает сервис?",
            text1st: "Semantix работает на базе технологии искусственного интеллекта (ИИ). Благодаря этому он преобразует речь в текст за короткое время и делает это максимально точно."
        }, {
            question: "Сколько времени приложение расшифровывает записи?",
            text1st: "Даже длинные записи сервис транскрибирует максимально быстро. Например, чтобы расшифровать часовой видеофайл, ему потребуется примерно 10 минут."
        }, {
            question: "Насколько точно программа расшифровывает речь?",
            text1st: "Точность транскрибации составляет 95%. Но на конечный результат влияет и качество файла. Чтобы улучшить речь на записи, воспользуйтесь дополнительными опциями Semantix."
        }
    ],
    "/mp4totext": [
        {
            question: "Как Semantix транскрибирует видео и аудио?",
            text1st: "Распознавание текста из других медиафайлов происходит с помощью технологии искусственного интеллекта. Поэтому сервис расшифровывает видео и аудио быстро и максимально точно."
        }, {
            question: "Сколько стоит конвертация?",
            text1st: "Стоимость расшифровки одной минуты начинается от 1,5 рублей. У нас есть несколько тарифов — выберите наиболее подходящий."
        }, {
            question: "Насколько точно сервис преобразует MP4 в текст?",
            text1st: "Нейросеть расшифровывает файлы с точностью 95%. Но в готовом документе могут быть небольшие неточности. Исправить их можно прямо в браузере."
        }, 
    ],
    "/mp3totext": [
        {
            question: "Сколько времени занимает транскрибация аудиофайла?",
            text1st: "Зависит от того, сколько длится аудио. Например, если вы загрузили файл с часовой записью, то расшифровка займет не больше 10 минут."
        }, {
            question: "Какие файлы, кроме MP3, умеет транскрибировать сервис?",
            text1st: "Semantix поддерживает аудио в форматах M4A, MP3, OGG, AAC, WAV, FLAC, WMA, а также видео в форматах MP4, MKV, FLV, AVI, MOV, WMV."
        }, {
            question: "Можно ли создать субтитры с помощью сервиса?",
            text1st: "Разумеется! Вы можете расшифровать аудиофайл и получить готовые субтитры: сервис автоматически расставит тайм-коды. Вам нужно только скачать файл в формате srt."
        }, {
            question: "Как Semantix расшифровывает аудио?",
            text1st: "Конвертер переводит файлы в текст с помощью технологии искусственного интеллекта (ИИ). Поэтому с помощью инструмента можно быстро получить качественную расшифровку."
        }, {
            question: "У вас есть техподдержка?",
            text1st: "Разумеется! Если у вас возникли вопросы по работе сервиса, или вы не можете загрузить файл нужного формата, отправьте нам письмо на почту: ",
            linkName: "hello@semantix.one",
            href: "mailto:hello@semantix.one"
        },
    ],
    "/convert-interviews": [
        {
            question: "Какие файлы можно транскрибировать?",
            text1st: "Программа поддерживает разные варианты файлов:",
            list1st: "Аудио: M4A, MP3, OGG, AAC, WAV, FLAC, WMA",
            list2nd: "Видео: MP4, MKV, FLV, AVI, MOV, WMV",
            moretext1st: "Если нужного вам формата нет, свяжитесь с нами."
        }, {
            question: "Насколько точно сервис расшифровывает интервью?",
            text1st: "Транскрипт совпадает с речью из записи интервью на 95%. Но иногда в расшифровке появляются неточности, исправить их можно прямо в браузере с помощью онлайн-редактора."
        }, {
            question: "Сколько времени программа переводит видео- и аудиофайлы в текст?",
            text1st: "Зависит от длительности записи, количества говорящих, плотности речи. Например, если видео или аудио длится один час, сервис расшифрует файл за 10 минут. "
        }, {
            question: "Сколько стоит расшифровка одной минуты?",
            text1st: "Цена транскрибации стартует с 1,5 рублей за минуту. Стоимость различается на разных тарифах. Чем больше файлов вы расшифровываете, тем меньше цена минуты."
        }
    ]
};