import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const fileManagerSlice = createSlice({
    name: 'openFileManager',
    initialState: {
        state: false,
        fileIdsList: [],
        folderTotalFiles: null
    },
    reducers: {
        setFileManagerState: (state, action: PayloadAction<boolean>) => {
            state.state = action.payload;
        },
        setFileManagerFileList: (state, action: PayloadAction<number | []>) => {
            if (!state.fileIdsList.includes(action.payload)) {
                state.fileIdsList = state.fileIdsList.concat(action.payload);
            } else {
                const index = state.fileIdsList.indexOf(action.payload);
                if (index > -1) {
                    state.fileIdsList.splice(index, 1);
                }
            }
        },
        setClearFileManagerFileList: (state, action: PayloadAction<[]>) => {
            if (action.payload.length === 0) {
                state.fileIdsList = [];
            }
        },
        setFolderManagerTotalFiles: (state, action: PayloadAction<number>) => {
            state.folderTotalFiles = action.payload;
        }
    }
})

export const { 
    setFileManagerState, 
    setFileManagerFileList, 
    setFolderManagerTotalFiles, 
    setClearFileManagerFileList 
} = fileManagerSlice.actions;

export default fileManagerSlice.reducer;