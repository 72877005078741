import { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
    IndexWidgetBody,
    IndexWidgetBlock,
    IndexWidgetBlockAnimation,
    IndexWidgetBlockAnimationLine,
    IndexWidgetBackground,
    IndexWidgetPattern,
    IndexWidgetFormBlock,
    IndexWidgetInputFileField,
    IndexWidgetTitle,
    IndexWidgetMainButton,
    IndexWidgetText,
    IndexWidgetInputBlock,
    IndexWidgetInput,
    IndexWidgetInputButton,
    IndexWidgetInputButtonIcon,
    IndexWidgetFile,
    IndexWidgetDeleteButton,
    IndexWidgetMainDeleteButton,
    IndexWidgetCheckboxBlock,
    IndexWidgetCheckboxInput,
    IndexWidgetCheckboxText,
    IndexWidgetCheckboxLink,
    IndexWidgetProgressBar,
    IndexWidgetProcessingBlock,
    IndexWidgetProcessingFileIcon,
    IndexWidgetProcessingIcon,
    IndexWidgetTrancriptionContent,
    IndexWidgetTranscriptionBlock,
    IndexWidgetTranscriptionTimestamp,
    IndexWidgetTranscriptionParagraph,
    IndexWidgetTranscriptionWord,
    IndexWidgetTranscriptionScrollbarTrack,
    IndexWidgetTranscriptionScrollbarThumb,
    IndexWidgetControlBar,
    IndexWidgetControlBarButton,
    IndexWidgetControlBarButtonIcon,
    IndexWidgetMobileBlurredRectangle,
    IndexWidgetError,
    IndexWidgetForm,
    borderDefaultStyle,
    borderFocusStyle
} from "./IndexWidgetStyles";
import { parseFileLength } from "utils/parseFileLength";
import { useDispatch, useSelector } from "react-redux";
import { setWidgetFileState } from "slices/widgetFileSlice";
import { setRequestInterval } from "slices/requestIntervalSlice";
import { RootState } from "slices";
import axios from "axios";
import { getSlicedFilename } from "utils/getSlicedFilename";

interface IWindowSize {
    windowWidth: number;
    windowHeight: number;
}

interface IWords {
    word: string;
    start: number;
    end: number;
}

interface ISegment {
    id?: number;
    text: string;
    start: number;
    end?: number;
    words?: IWords[];
}

interface IResult {
    text: string;
    segments: ISegment[];
    language: string;
}

interface IFileView {
    finalResults: {
        result: IResult;
        userResult: IResult;
    }
}

interface IGroupedSegment extends Array<ISegment>{};

const IndexWidgetInterface = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const abortControllerRef = useRef<AbortController>(new AbortController()); 
    const [abortedRequest, setAbortedRequest] = useState<number>(0);
    const [data, setData] = useState<Array<File>>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [fileError, setFileError] = useState<string>(null);
    const [fileStatus, setFileStatus] = useState<string>(null);
    const [fileProgress, setFileProgress] = useState<number>(null);
    const [clippedHeight, setClippedHeight] = useState<number>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [widgetReady, setWidgetReady] = useState<boolean>(false);
    const [uploadProcess, setUploadProcess] = useState<boolean>(false);
    const [inputBorderStyle, setInputBorderStyle] = useState<string>(borderDefaultStyle);
    const [checkboxActive, setCheckboxActive] = useState<boolean>(false);
    const [groupedSegments, setGroupedSegments] = useState<IGroupedSegment>([]);
    const [windowSize, setWindowSize] = useState<IWindowSize>({ 
        windowWidth: window.innerWidth, windowHeight: window.innerHeight 
    });
    const requestInterval = useSelector((state: RootState) => state.requestInterval.value);
    const dispatch = useDispatch();
    const contentRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);
    const trackRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!localStorage.getItem("userData")) {
            requestResult();
        } else {
            setWidgetReady(true);
        }
    }, []);

    useEffect(() => {
        const contentBlock = contentRef.current;
        const thumb = thumbRef.current;
        const track = trackRef.current;

        const handleScroll = () => {
            const container = containerRef.current;
            const track = trackRef.current;
            const thumb = thumbRef.current;
    
            const scrollTop = container.scrollTop;
            const trackHeight = track.offsetHeight;
    
            const maxScrollTop = contentBlock.scrollHeight - clippedHeight;
            const maxThumbTop = trackHeight - thumb.offsetHeight;
            const thumbTop = (scrollTop / maxScrollTop) * maxThumbTop;
            thumb.style.top = `${thumbTop}px`;
        };

        const onMouseMove = (e: MouseEvent) => {
            if (isDragging) {
                const trackRect = track.getBoundingClientRect();
                const thumbHalfHeight = thumb.offsetHeight / 2;

                const newThumbTop = Math.min(
                    Math.max(0, e.clientY - trackRect.top - thumbHalfHeight),
                    track.offsetHeight - thumb.offsetHeight
                );

                thumb.style.top = `${newThumbTop}px`;

                const maxScrollTop = contentBlock.scrollHeight - clippedHeight;
                const newScrollTop = (newThumbTop / (track.offsetHeight - thumb.offsetHeight)) * maxScrollTop;

                contentBlock.scrollTop = newScrollTop;
                handleScroll();
            }
        };

        const onMouseUp = () => {
            setIsDragging(false);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [isDragging, clippedHeight]);

    useEffect(() => {
        function handleRequests() {
            if (document.hidden && requestInterval) {
                clearInterval(requestInterval);
                dispatch(setRequestInterval(null));
            } else if (!document.hidden && !requestInterval) {
                console.log('visibility change set interval')
                if (fileStatus === "transcribing") {
                    dispatch(setRequestInterval(setInterval(requestResult, 30000)));
                }  
            }
        }
        document.addEventListener("visibilitychange", handleRequests)
        return () => 
            document.removeEventListener("visibilitychange", handleRequests)
    }, [requestInterval]);

    useEffect(() => {
        if (fileStatus === "transcribing") {
            console.log('set interval')
            dispatch(setRequestInterval(setInterval(requestResult, 30000)));
        } else {
            dispatch(setRequestInterval(null));
        }
        return () => { dispatch(setRequestInterval(null)); }
    },[fileStatus]);

    useEffect(() => {
        function handleBeforeUnload(e: BeforeUnloadEvent): void {
            e.preventDefault();
            e.returnValue = "";
        }
        let handler = null;
        if (uploadProcess) {
            handler = (e: BeforeUnloadEvent) => handleBeforeUnload(e);
            window.addEventListener("beforeunload", handler);
        }
        return () => {
            if (handler) {
                window.removeEventListener("beforeunload", handler);
            } 
        }
    }, [uploadProcess]);

    useEffect(() => {
        function handleWidgetScroll(
            element: HTMLElement | Element, 
            scrollHeight: number, 
            trackHeight: number): void 
        {
            const value = element.scrollTop / (scrollHeight / trackHeight);
            document.getElementById("scrollbar_thumb").style.transform = `translate3d(0px, ${value}px, 0px`;
        }
        if (fileStatus === "ready" && widgetReady) {
            const contentBlock = contentRef.current;
            const trackHeight = document.getElementById("scrollbar_track").offsetHeight;
            
            if (contentBlock.scrollHeight <= clippedHeight) {
                document.getElementById("scrollbar_track").style.display = "none";
            } else {
                document.getElementById("scrollbar_track").style.display = "unset";
            }
            
            const thumbHeight = trackHeight * (clippedHeight / contentBlock.scrollHeight);
            document.getElementById("scrollbar_thumb").style.height = `${thumbHeight}px`;

            contentBlock.addEventListener("scroll", function() {
                handleWidgetScroll(contentBlock, contentBlock.scrollHeight, trackHeight);
            });
            return () => {
                contentBlock.addEventListener("scroll", function() {
                    handleWidgetScroll(contentBlock, contentBlock.scrollHeight, trackHeight);
                });
            }
        }
    }, [groupedSegments, windowSize, clippedHeight, widgetReady, fileStatus]);

    useEffect(() => {
        function updateWindowSize() {
            setWindowSize({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            })
        }
        function updateClippedHeight() {
            setTimeout(() => {
                setClippedHeight(containerRef.current.offsetHeight);
            }, 250);
        }
        if (groupedSegments.length > 0) {
            updateClippedHeight();
            window.addEventListener("resize", function() {
                updateWindowSize();
                updateClippedHeight();
            });
            return () =>
                window.removeEventListener("resize", function() {
                    updateWindowSize();
                    updateClippedHeight();
                });
        }
    }, [groupedSegments]);

    useEffect(() => {
        abortControllerRef.current = new AbortController();
        const controller = abortControllerRef.current;

        return () => {
            controller.abort();
        };
    }, [abortedRequest]);

    useEffect(() => {
        if (fileStatus === "ready" && window.innerWidth <= 500) {
            const content = document.getElementById("greeting_content") as HTMLElement;
            content.scrollIntoView({behavior: "smooth"});
        }
    }, [fileStatus]);

    function requestResult() {
        axios.get("/api/widget/files/get", {
            withCredentials: true
        })
        .then((res) => {
            const status = res.data.status.code;
            if (status < 7) {
                setFileStatus("transcribing");
            } else if (status === 7) {
                setFileStatus("ready");
                dispatch(setWidgetFileState("ready"));
                groupSegments(res.data, 1);
            } else if (status === 8) {
                setFileStatus(null);
                dispatch(setWidgetFileState(null));
                setFileError(res.data.status.text);
            }
            setWidgetReady(true);
        })
        .catch((err) => {
            setWidgetReady(true);
            if (err.response.status) {
                if (err.response.status === 403 || err.response.status === 401) {
                    localStorage.removeItem("userData");
                } else if (err.response.status === 503) {
                    setFileError("Превышен лимит частоты для отправки запросов, попробуйте снова позже")
                }
            }
        })
    }

    const handleDrop = function(e: React.DragEvent<HTMLDivElement>): void {
        e.preventDefault();
        e.stopPropagation();
        setInputBorderStyle(borderDefaultStyle);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (e.dataTransfer.files[0].size === 0) {
                setFileError("Обнаружен файл с нулевым размером, загрузка отменена");
            } else if (!checkFileExt(e.dataTransfer.files[0])) {
                setFileError("Обнаружен файл с недопустимым форматом, загрузка отменена");
            } else if (e.dataTransfer.files[0].size > 2147483648) {
                setFileError("Превышен допустимый лимит размера файла в 2Гб.");
            } else {
                if (fileError) setFileError(null);
                setData([e.dataTransfer.files[0]]);
            }
        }
    };

    const handleChange = function(e: React.ChangeEvent<HTMLInputElement>): void {
        e.preventDefault();
        const target = (e.currentTarget as HTMLInputElement);
        if (target.files && target.files[0]) {
            if (target.files[0].size === 0) {
                setFileError("Обнаружен файл с нулевым размером, загрузка отменена");
            }
            if (!checkFileExt(target.files[0])) {
                setFileError("Обнаружен файл с недопустимым форматом, загрузка отменена");
            } else if (target.files[0].size > 2147483648) {
                setFileError("Превышен допустимый лимит размера файла в 2Гб.");
            } else {
                if (fileError) setFileError(null);
                setData([target.files[0]]);
            }
        }
    };

    const handleFileUpload = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let uploadError = false;

        const config = {
            folder: {
                id: 0,
                name: ""
            }
        };
        axios.post("/api/widget/tasks/new", {
            files: data,
            inputValues: JSON.stringify(config)
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: data => {
                setFileProgress(Math.round((100 * data.loaded) / data.total));
            },
            signal: abortControllerRef.current.signal
        })
        .then(() => {
            setUploadProcess(false);
            requestResult();
        })
        .catch((err) => {
            uploadError = true;
            if (err.response.status) {
                if (err.response.status === 403 || err.response.status === 401) {
                    localStorage.removeItem("userData");
                } else if (err.response.status === 503) {
                    setFileError("Превышен лимит частоты для отправки запросов, попробуйте снова позже")
                } else if (err.response.status === 409) {
                    setFileError(err.response.data);
                    setFileStatus(null);
                    dispatch(setWidgetFileState(null));
                    setData([]);
                }
            }
        })
        .catch(function(thrown) {
            if (axios.isCancel(thrown)) {
                console.log('Request', thrown.message);
            }
        })
        console.log(uploadError)
        if (!uploadError) {
            setUploadProcess(true);
            setFileStatus("sending");
        }
    }

    const checkFileExt = (file: File): boolean => {
        const ext = file.name.match(/\.([^\.]+)$/)[1];
        switch (ext.toLowerCase()) {
            case 'mp3':
            case 'mpeg':
            case 'mpg':
            case 'mov':
            case 'mkv':
            case 'mxf':
            case 'avi':
            case 'mts':
            case '3gp':
            case 'amr':
            case 'wav':
            case 'flv':
            case 'wmv':
            case 'm4a':
            case 'ogg':
            case 'aac':
            case 'flac':
            case 'wma':
            case 'mp4':
                return true;
            default:
                return false;
        }
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const input = e.currentTarget.value;
        setInputValue(input);
    }

    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        inputRef.current.click();
    };

    const groupSegments = (result: IFileView, n: number): void => {
        let group: IGroupedSegment = [];
        let segment: string[] = [];
        const userResult = result.finalResults.result;
        for (let i = 0, j = 0; i < userResult.segments.length; i++) {
            if (i >= n && i % n === 0) {
                group[j] = {
                    start: userResult.segments[i].start,
                    text: segment.join('')
                };
                segment = [];
                j++;
            }
            segment.push(userResult.segments[i].text);
            if (i === userResult.segments.length - 1)  {
                setGroupedSegments(group);
            }
        }
    }

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        setIsDragging(true);
    };

    if (widgetReady) {
        if (fileStatus === "transcribing" && !fileError) {
            return (
                <IndexWidgetBackground>
                    <IndexWidgetPattern 
                        alt="pattern" 
                        src="/images/widget-pattern.webp"
                    />
                    <IndexWidgetTitle>
                        Выполнятеся расшифровка файла, пожалуйста подождите.
                    </IndexWidgetTitle>
                    <IndexWidgetProcessingBlock>
                        <IndexWidgetProcessingFileIcon
                            alt="file"
                            src="/images/transcription-file.svg"
                        />
                        <IndexWidgetProcessingIcon width="76" height="38" viewBox="0 0 76 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="firstArrow" d="m14.25 9.5 9.5 9.5-9.5 9.5" stroke-width="3.167" strokeLinecap="round" strokeLinejoin="round"/>
                            <path className="secondArrow" d="m33.25 9.5 9.5 9.5-9.5 9.5" stroke-width="3.167" strokeLinecap="round" strokeLinejoin="round"/>
                            <path className="thirdArrow" d="m52.25 9.5 9.5 9.5-9.5 9.5" stroke-width="3.167" strokeLinecap="round" strokeLinejoin="round"/>
                        </IndexWidgetProcessingIcon>
                        <IndexWidgetProcessingFileIcon 
                            alt="transcription" 
                            src="/images/widget-transcription.svg"
                        />
                    </IndexWidgetProcessingBlock>
                </IndexWidgetBackground>
            );
        } if (fileStatus === "ready" && groupedSegments) {
            return (
                <IndexWidgetBackground id="widget_overflow_block" ref={containerRef}>
                    <IndexWidgetPattern alt="pattern" src="/images/widget-pattern.webp" />
                    <IndexWidgetTrancriptionContent id="content_block" ref={contentRef}>
                        {groupedSegments.map((segment, idx) => {
                            return (
                                <IndexWidgetTranscriptionBlock id="transcript" key={idx}>
                                    <IndexWidgetTranscriptionTimestamp>
                                        {parseFileLength(segment.start, true)}
                                    </IndexWidgetTranscriptionTimestamp>
                                    <IndexWidgetTranscriptionParagraph>
                                        <IndexWidgetTranscriptionWord>
                                            {segment.text}
                                        </IndexWidgetTranscriptionWord>
                                    </IndexWidgetTranscriptionParagraph>
                                </IndexWidgetTranscriptionBlock>
                            );
                        })}
                    </IndexWidgetTrancriptionContent>
                    <IndexWidgetTranscriptionScrollbarTrack 
                        id="scrollbar_track"
                        ref={trackRef}
                    >
                        <IndexWidgetTranscriptionScrollbarThumb 
                            id="scrollbar_thumb"
                            ref={thumbRef} 
                            onMouseDown={(e) => { onMouseDown(e); }} 
                        />
                    </IndexWidgetTranscriptionScrollbarTrack>
                </IndexWidgetBackground>
            );
        } else {
            return (
                <>
                    {data && data.length === 0 ? (
                        <IndexWidgetForm>
                            <IndexWidgetBackground
                                id="input_file"
                                onSubmit={(e) => e.preventDefault()}
                                onDragOver={(e) => { e.preventDefault(); setInputBorderStyle(borderFocusStyle); }}
                                onDragLeave={(e) => { e.preventDefault(); setInputBorderStyle(borderDefaultStyle); }}
                                onDrop={handleDrop}
                                $borderStyle={inputBorderStyle}
                            >
                                <IndexWidgetPattern 
                                    alt="pattern" 
                                    src="/images/widget-pattern.webp"
                                />
                                <IndexWidgetFormBlock>
                                    <IndexWidgetInputFileField 
                                        type="file"
                                        accept=".mp3,.mpeg,.mpg,.mov,.mkv,.mxf,.avi,.mts,.3gp,.amr,.wav,.flv,.mov,.wmv,.m4a,.ogg,.aac,.flac,.wma,.mp4"
                                        id="input_file_field" 
                                        multiple={false} 
                                        ref={inputRef}
                                        onChange={handleChange}
                                    />
                                    <IndexWidgetTitle>
                                        Перетащите медиа в выделенную область,<br /> или нажмите “Выбрать файл”
                                    </IndexWidgetTitle>
                                    <IndexWidgetMainButton onClick={(e) => { onButtonClick(e); }}>
                                        Выбрать файл
                                    </IndexWidgetMainButton>
                                    {/* <IndexWidgetText>
                                        Или вставьте ссылку на файл
                                    </IndexWidgetText>
                                    <IndexWidgetInputBlock>
                                        <IndexWidgetInput
                                            type="text"
                                            placeholder="Я.Диск, Google диск"
                                            disabled={true}
                                            onChange={(e) => { handleInput(e); }}
                                        />
                                        <IndexWidgetInputButton 
                                            type="button"
                                            disabled={inputValue === ""}
                                        >
                                            <IndexWidgetInputButtonIcon className={inputValue ? "active" : ""} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 15.8926V4.10744M10 4.10744L4.69672 9.41074M10 4.10744L15.3033 9.41074" strokeLinecap="round" strokeLinejoin="round"/>
                                            </IndexWidgetInputButtonIcon>
                                        </IndexWidgetInputButton>
                                    </IndexWidgetInputBlock> */}
                                    <IndexWidgetError>
                                        {fileError}
                                    </IndexWidgetError>
                                </IndexWidgetFormBlock>
                            </IndexWidgetBackground>
                        </IndexWidgetForm>
                    ) : (
                        <IndexWidgetBackground>
                            <IndexWidgetPattern alt="pattern" src="/images/widget-pattern.webp" />
                            {data && data.length === 1 && (
                                <IndexWidgetFile>
                                    {getSlicedFilename(data[0].name)}
                                    <IndexWidgetDeleteButton 
                                        type="button" 
                                        onClick={() => { setData([]); setCheckboxActive(false); }} 
                                    />
                                </IndexWidgetFile>
                            )}
                            {fileStatus === "sending" ? (
                                <>
                                    <IndexWidgetText>
                                        Идёт отправка файла
                                    </IndexWidgetText>
                                    <IndexWidgetProgressBar max="100" value={fileProgress}/>
                                    <IndexWidgetMainDeleteButton 
                                        type="button" 
                                        onClick={() => { 
                                            setData([]); 
                                            abortControllerRef.current.abort();
                                            setAbortedRequest((current) => current + 1);
                                            setFileStatus(null); 
                                            setCheckboxActive(false);
                                        }}
                                    >
                                        Отменить
                                    </IndexWidgetMainDeleteButton>
                                </>
                            ) : (
                                <IndexWidgetForm onSubmit={(e) =>{ handleFileUpload(e); }}>
                                    <label>
                                        <IndexWidgetCheckboxBlock>
                                            <IndexWidgetCheckboxInput
                                                type="checkbox"
                                                defaultChecked={checkboxActive}
                                                onChange={() => { setCheckboxActive(current => !current); }}
                                            />
                                            <IndexWidgetCheckboxText>
                                                Я ознакомлен (-на) и согласен (-на) с
                                                <IndexWidgetCheckboxLink to="/offer" target="_blank" rel="noopener noreferrer">
                                                    Публичной офертой
                                                </IndexWidgetCheckboxLink>
                                                и
                                                <IndexWidgetCheckboxLink to="/agreement" target="_blank" rel="noopener noreferrer">
                                                    Политикой конфиденциальности
                                                </IndexWidgetCheckboxLink>
                                                , даю свое согласие на обработку моих персональных данных
                                            </IndexWidgetCheckboxText>
                                        </IndexWidgetCheckboxBlock>
                                    </label>
                                    <IndexWidgetMainButton 
                                        disabled={!checkboxActive}
                                        className="processing_button"
                                        type="submit"
                                    >
                                        Отправить файл
                                    </IndexWidgetMainButton>
                                </IndexWidgetForm>
                            )}
                        </IndexWidgetBackground>
                    )}
                </>
            );
        }
    }
}

const IndexWidgetComponent = () => {
    const [controlBar, setControlBar] = useState<boolean>(false);

    return (
        <>
            <IndexWidgetBody>
                <IndexWidgetBlock>
                    <IndexWidgetBlockAnimation>
                        <IndexWidgetBlockAnimationLine />
                        <IndexWidgetBlockAnimationLine className="second_line" />
                    </IndexWidgetBlockAnimation>
                    <IndexWidgetInterface />
                </IndexWidgetBlock>
                <IndexWidgetMobileBlurredRectangle />
                {controlBar && (
                    <IndexWidgetControlBar>
                        <IndexWidgetControlBarButton >
                            <IndexWidgetControlBarButtonIcon 
                                alt="export" 
                                src="/images/export-icon.svg"
                            />
                        </IndexWidgetControlBarButton>
                        <IndexWidgetControlBarButton >
                            <IndexWidgetControlBarButtonIcon 
                                alt="copy" 
                                src="/images/copy-icon.svg"
                            />
                        </IndexWidgetControlBarButton>
                    </IndexWidgetControlBar>
                )}
            </IndexWidgetBody>
            {controlBar && (
                <IndexWidgetControlBar className="mobile_controls">
                    <IndexWidgetControlBarButton >
                        <IndexWidgetControlBarButtonIcon 
                            alt="export" 
                            src="/images/export-icon.svg"
                        />
                    </IndexWidgetControlBarButton>
                    <IndexWidgetControlBarButton >
                        <IndexWidgetControlBarButtonIcon 
                            alt="copy" 
                            src="/images/copy-icon.svg"
                        />
                    </IndexWidgetControlBarButton>
                </IndexWidgetControlBar>
            )}
        </>
    );
} 

export default IndexWidgetComponent;