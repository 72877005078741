import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const handleSeekSlice = createSlice({
    name: 'handleSeek',
    initialState: {
        value: null
    },
    reducers: {
        setHandleSeekSlice: (state, action: PayloadAction<(value: number) => void>) => {
            state.value = action.payload;
        }
    }
})

export const { setHandleSeekSlice } = handleSeekSlice.actions;

export default handleSeekSlice.reducer;