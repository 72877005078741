import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { 
    IndexSeoComponentBlock,
    IndexSeoComponentBackgroundBlock,
    IndexSeoComponentUpperBackgroundPicture,
    IndexSeoComponentLowerBackgroundPicture,
    IndexSeoComponentUpperBackgroundImage,
    IndexSeoComponentLowerBackgroundImage,
    IndexSeoComponentContent,
    IndexSeoComponentContentLine,
    IndexSeoComponentContentTitle,
    IndexSeoComponentContentSubTitle,
    IndexSeoComponentContentList,
    IndexSeoComponentContentListItem,
    IndexSeoComponentContentText,
    IndexSeoComponentContentParagraph,
    IndexSeoComponentButton
} from "./IndexSeoComponentStyles";
import { seoBlockContent } from "content/seoBlockContent";
import * as DOMPurify from 'dompurify';

interface IIndexSeoItem {
    title: string;
    text1st?: string;
    text2nd?: string;
    text3rd?: string;
    text4th?: string;
    text5th?: string;
    text6th?: string;
    text7th?: string;
    text8th?: string;
    text9th?: string;
    text1stInnerHtml?: string;
    list1stInnerHtml?: string;
    list2ndInnerHtml?: string;
    list1st?: string;
    list2nd?: string;
    list3rd?: string;
    list4th?: string;
    list5th?: string;
    list6th?: string;
    list7th?: string;
    list8th?: string;
    list9th?: string;
    subParagraph?: {
        title: string;
        text1st: string;
        text2nd?: string;
    }[];
}

interface IIndexSeoComponentItem {
    item: IIndexSeoItem;
    blockNum: number;
}

const IndexSeoComponentItem: FC<IIndexSeoComponentItem> = ({item, blockNum}) => {
    if (item) {
        return (
            <IndexSeoComponentContent className={blockNum && blockNum > 0 ? "expanded_content" : ""}>
                {blockNum && blockNum > 0 && <IndexSeoComponentContentLine />}
                <IndexSeoComponentContentTitle>{item.title}</IndexSeoComponentContentTitle>
                <IndexSeoComponentContentText>
                    {item.text1st && <IndexSeoComponentContentParagraph>{item.text1st}</IndexSeoComponentContentParagraph>}
                    {item.text1stInnerHtml && 
                        <IndexSeoComponentContentParagraph 
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text1stInnerHtml) }} 
                        />}
                    {item.list1stInnerHtml && 
                        <IndexSeoComponentContentListItem 
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.list1stInnerHtml) }} 
                        />}
                    {item.list2ndInnerHtml && 
                        <IndexSeoComponentContentListItem 
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.list2ndInnerHtml) }} 
                        />}
                    {item.text2nd && <IndexSeoComponentContentParagraph>{item.text2nd}</IndexSeoComponentContentParagraph>}
                    {item.text3rd && <IndexSeoComponentContentParagraph>{item.text3rd}</IndexSeoComponentContentParagraph>}
                    {item.list1st && <IndexSeoComponentContentListItem>{item.list1st}</IndexSeoComponentContentListItem>}
                    {item.list1st && <IndexSeoComponentContentListItem>{item.list1st}</IndexSeoComponentContentListItem>}
                    {item.list2nd && <IndexSeoComponentContentListItem>{item.list2nd}</IndexSeoComponentContentListItem>}
                    {item.list3rd && <IndexSeoComponentContentListItem>{item.list3rd}</IndexSeoComponentContentListItem>}
                    {item.list4th && <IndexSeoComponentContentListItem>{item.list4th}</IndexSeoComponentContentListItem>}
                    {item.list5th && <IndexSeoComponentContentListItem>{item.list5th}</IndexSeoComponentContentListItem>}
                    {item.list6th && <IndexSeoComponentContentListItem>{item.list6th}</IndexSeoComponentContentListItem>}
                    {item.list7th && <IndexSeoComponentContentListItem>{item.list7th}</IndexSeoComponentContentListItem>}
                    {item.list8th && <IndexSeoComponentContentListItem>{item.list8th}</IndexSeoComponentContentListItem>}
                    {item.list9th && <IndexSeoComponentContentListItem>{item.list9th}</IndexSeoComponentContentListItem>}
                    
                    {item.subParagraph && item.subParagraph.map((sub, subIndex) => (
                        <div key={subIndex}>
                            <IndexSeoComponentContentSubTitle>{sub.title}</IndexSeoComponentContentSubTitle>
                            <IndexSeoComponentContentParagraph>{sub.text1st}</IndexSeoComponentContentParagraph>
                            {sub.text2nd && <IndexSeoComponentContentParagraph>{sub.text2nd}</IndexSeoComponentContentParagraph>}
                        </div>
                    ))}
                    {item.text4th && <IndexSeoComponentContentParagraph>{item.text4th}</IndexSeoComponentContentParagraph>}
                    {item.text5th && <IndexSeoComponentContentParagraph>{item.text5th}</IndexSeoComponentContentParagraph>}
                    {item.text6th && <IndexSeoComponentContentParagraph>{item.text6th}</IndexSeoComponentContentParagraph>}
                    {item.text7th && <IndexSeoComponentContentParagraph>{item.text7th}</IndexSeoComponentContentParagraph>}
                    {item.text8th && <IndexSeoComponentContentParagraph>{item.text8th}</IndexSeoComponentContentParagraph>}
                </IndexSeoComponentContentText>
            </IndexSeoComponentContent>
        );
    }
}
 
const IndexSeoComponent = () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [content, setContent] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const content = seoBlockContent[location.pathname];
        setContent(content);
        setExpanded(false);
    }, [location]);

    if (content) {
        return (
            <IndexSeoComponentBlock>
                <IndexSeoComponentBackgroundBlock>
                    <IndexSeoComponentUpperBackgroundPicture>
                        <source media="(max-width:500px)" srcSet="/images/seo-upper-bg-mobile.svg" />
                        <IndexSeoComponentUpperBackgroundImage alt="background" src="/images/seo-upper-bg.svg" />
                    </IndexSeoComponentUpperBackgroundPicture>
                    <IndexSeoComponentLowerBackgroundPicture>
                        <source media="(max-width:500px)" srcSet="/images/seo-lower-bg-mobile.svg" />
                        <IndexSeoComponentLowerBackgroundImage alt="background" src="/images/seo-lower-bg.svg" />
                    </IndexSeoComponentLowerBackgroundPicture>
                    {!expanded ? (
                        <IndexSeoComponentItem item={{...content[0]}} blockNum={0} />
                    ) : (
                        content.map((item: IIndexSeoItem, idx) => {
                            return (
                                <IndexSeoComponentItem key={idx} item={item} blockNum={idx} />
                            );
                        })
                    )}
                </IndexSeoComponentBackgroundBlock>
                {content.length > 1 && (
                    <IndexSeoComponentButton onClick={() => { setExpanded(current => !current); }}>
                        {expanded ? "Скрыть" : "Узнать больше"}
                    </IndexSeoComponentButton>
                )}
            </IndexSeoComponentBlock>
        );
    }
}

export default IndexSeoComponent;