import styled from "styled-components";
import pxIntoRem from "utils/pxIntoRem";

const IndexSeoComponentBlock = styled.div`
  position: relative;
  width: 100%;
`;

const IndexSeoComponentBackgroundBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: ${pxIntoRem(60)} ${pxIntoRem(75)};
  @media (max-width: 500px) {
    padding: ${pxIntoRem(40)} ${pxIntoRem(25)};
  }
`;

const IndexSeoComponentUpperBackgroundPicture = styled.picture`
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  padding: 0 ${pxIntoRem(35)};
  filter: drop-shadow(0px 24px 234px rgba(0, 0, 0, 0.20));
  @media (max-width: 500px) {
    padding: 0;
    aspect-ratio: initial;
  }
`;

const IndexSeoComponentLowerBackgroundPicture = styled.picture`
  position: absolute;
  width: 100%;
  z-index: 99;
  bottom: 0;
  left: 0;
  padding: 0 ${pxIntoRem(35)};
  filter: drop-shadow(0px 24px 234px rgba(0, 0, 0, 0.20));
  @media (max-width: 500px) {
    padding: 0;
    aspect-ratio: initial;
  }
`;

const IndexSeoComponentUpperBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

const IndexSeoComponentLowerBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

const IndexSeoComponentContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 99999;
  &.expanded_content {
    margin-top: ${pxIntoRem(80)};
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const IndexSeoComponentContentTitle = styled.h2`
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(28)};
  font-style: normal;
  font-weight: 550;
  line-height: 100%;
  margin-left: 0;
  width: ${pxIntoRem(404)};
  span {
    color: #1683E2;
  }
  @media (max-width: 500px) {
    width: calc(100vw - ${pxIntoRem(50)});
    font-size: ${pxIntoRem(20)};
    margin-bottom: ${pxIntoRem(16)};
  }
`;

const IndexSeoComponentContentSubTitle = styled.h3`
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(20)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;  
  margin-bottom: ${pxIntoRem(8)};
  &.mobile_break {
    display: none;
  }
  @media (max-width: 500px) {
    width: calc(100vw - ${pxIntoRem(50)});
    &.mobile_break {
      display: block;
    }
  }
`;

const IndexSeoComponentContentText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(16)};
  margin-left: auto;
  margin-right: 0;
  z-index: 99999;
  width: ${pxIntoRem(640)};
  @media (max-width: 500px) {
    width: calc(100vw - ${pxIntoRem(50)});
    gap: ${pxIntoRem(14)};
  }
`;

const IndexSeoComponentContentParagraph = styled.p`
  color: #848097;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(16)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: ${pxIntoRem(640)};
  transition: 0.3s;
  a {
    color: #FFF;
    text-decoration: underline;
    &:hover {
        color: #1683E2;
        transition: 0.3s;
    }
  }
  @media (max-width: 500px) {
    width: calc(100vw - ${pxIntoRem(50)});
  }
`;

const IndexSeoComponentContentList = styled.ul`
  list-style-position: inside;
`;

const IndexSeoComponentContentListItem = styled.li`
  color: #848097;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(16)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: ${pxIntoRem(640)};
  &::marker {
    font-size: ${pxIntoRem(20)};
    color: #1683E2;
  }
  span {
    color: #FFF;
  }
  @media (max-width: 500px) {
    width: calc(100vw - ${pxIntoRem(30)});
  }
`;

const IndexSeoComponentContentLine = styled.div`
  position: absolute;
  background: linear-gradient(270deg, rgba(24, 26, 40, 0.00) 0%, #181A28 50.61%, rgba(24, 26, 40, 0.00) 100%);
  height: 1px;
  width: calc(100% + ${pxIntoRem(80)});
  top: ${pxIntoRem(-40)};
  left: ${pxIntoRem(-40)};
`;

const IndexSeoComponentButton = styled.button`
  display: flex;
  width: ${pxIntoRem(200)};
  height: ${pxIntoRem(46)};
  padding: ${pxIntoRem(10)} ${pxIntoRem(50)};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${pxIntoRem(8)};
  border: 1px solid #2D3042;
  backdrop-filter: blur(${pxIntoRem(3)});
  margin: ${pxIntoRem(24)} auto ${pxIntoRem(34)} auto;
  background: transparent;
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(16)};
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  white-space: nowrap;
  transition: 0.3s;
  @media (min-width: 501px) {
    &:hover {
      border: 1px solid #FFF;
      transition: 0.3s;
    }
  }
`;

export {
  IndexSeoComponentBlock,
  IndexSeoComponentBackgroundBlock,
  IndexSeoComponentUpperBackgroundPicture,
  IndexSeoComponentLowerBackgroundPicture,
  IndexSeoComponentUpperBackgroundImage,
  IndexSeoComponentLowerBackgroundImage,
  IndexSeoComponentContent,
  IndexSeoComponentContentLine,
  IndexSeoComponentContentTitle,
  IndexSeoComponentContentSubTitle,
  IndexSeoComponentContentList,
  IndexSeoComponentContentListItem,
  IndexSeoComponentContentText,
  IndexSeoComponentContentParagraph,
  IndexSeoComponentButton
};