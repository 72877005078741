import styled from "styled-components";
import pxIntoRem from "utils/pxIntoRem";
import { Link } from "react-router-dom";

const IndexMiniFaqBlock = styled.div`
  position: relative;
  width: 100%;
`;

const IndexMiniFaqBackgroundBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  margin-bottom: ${pxIntoRem(134)};
  @media (max-width: 500px) {
    margin-bottom: ${pxIntoRem(84)};
  }
`;

const IndexMiniFaqBackgroundPicture = styled.picture`
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  padding: 0 ${pxIntoRem(35)};
  filter: drop-shadow(0px 24px 234px rgba(0, 0, 0, 0.20));
  @media (max-width: 500px) {
    padding: 0;
    aspect-ratio: initial;
  }
`;

const IndexMiniFaqUpperBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

const IndexMiniFaqTitle = styled.h2`
  color: #FFF;
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(38)};
  font-style: normal;
  font-weight: 550;
  line-height: 100%;
  margin-top: ${pxIntoRem(80)};
  z-index: 99999;
  @media (max-width: 500px) {
    margin-top: ${pxIntoRem(40)};
    font-size: ${pxIntoRem(24)};
  }
`;

const IndexMiniFaqQuestionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: ${pxIntoRem(60)} ${pxIntoRem(150)} 0 ${pxIntoRem(150)};
  width: calc(100% - ${pxIntoRem(300)});
  z-index: 999999;
  @media (max-width: 500px) {
    margin: ${pxIntoRem(30)} ${pxIntoRem(15)} 0 ${pxIntoRem(15)};
    width: calc(100% - ${pxIntoRem(30)});
  }
`;

const IndexMiniFaqQuestionElementIcon = styled.svg`
  position: absolute;
  width: ${pxIntoRem(32)};
  height: ${pxIntoRem(32)};
  top: ${pxIntoRem(20)};
  right: ${pxIntoRem(24)};
  stroke: #2D3042;
  path {
    stroke: #FFF;
  }
  @media (max-width: 500px) {
    top: ${pxIntoRem(20)};
    right: ${pxIntoRem(20)};
  }
`;

const IndexMiniFaqQuestionElement = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${pxIntoRem(16)};
  padding: ${pxIntoRem(25)} ${pxIntoRem(24)};
  background: transparent;
  transition: 0.3s;
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: ${pxIntoRem(16)};
    border: 1px solid transparent;
    background: linear-gradient(181deg, rgba(32, 34, 48, 0.7) 1.02%, rgba(32, 33, 41, 0) 128.15%) border-box;
    -webkit-mask:
                linear-gradient(#fff 0 0) padding-box, 
                linear-gradient(#fff 0 0);
            mask:
                linear-gradient(#fff 0 0) padding-box, 
                linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }
  @media (min-width: 501px) {
    &:hover ${IndexMiniFaqQuestionElementIcon} {
      stroke: #1683E2;
      path {
        stroke: #1683E2;
      }
      transition: 0.3s;
    }
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: ${pxIntoRem(20)};
    min-height: ${pxIntoRem(78)};
  }
`;

const IndexMiniFaqQuestionElementBackgroundLayer = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: rgba(28, 29, 40, 0.25);
  border-radius: ${pxIntoRem(16)};
`;

const IndexMiniFaqQuestionElementTitle = styled.h2`
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(18)};
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  z-index: 999;
  text-align: start;
  @media (max-width: 500px) {
    font-size: ${pxIntoRem(16)};
    margin-right: ${pxIntoRem(72)};
  }
`;

const IndexMiniFaqQuestionElementText = styled.p`
  display: none;
  position: relative;
  width: ${pxIntoRem(836)};
  color: #79768B;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: ${pxIntoRem(25)};
  text-align: start;
  z-index: 9;
  &.visible_text {
    display: inline;
  }
  span {
    color: FFF;
  }
  @media (max-width: 500px) {
    width: auto;
  }
`;

const IndexMiniFaqQuestionElementInnerLink = styled(Link)`
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: ${pxIntoRem(25)};
  text-align: start;
  z-index: 9;
  @media (min-width: 501px) {
    &:hover {
      color: #1683E2;
    }
  }
`;

const IndexMiniFaqQuestionElementList = styled.li`
  color: #79768B;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: ${pxIntoRem(12)};
  &:last-of-type {
    margin-bottom: ${pxIntoRem(12)};
  }
  &.last_list_element {
    margin-bottom: 0;
  }
`;

export {
  IndexMiniFaqBlock,
  IndexMiniFaqBackgroundBlock,
  IndexMiniFaqBackgroundPicture,
  IndexMiniFaqUpperBackgroundImage,
  IndexMiniFaqTitle,
  IndexMiniFaqQuestionsBlock,
  IndexMiniFaqQuestionElementIcon,
  IndexMiniFaqQuestionElement,
  IndexMiniFaqQuestionElementBackgroundLayer,
  IndexMiniFaqQuestionElementTitle,
  IndexMiniFaqQuestionElementText,
  IndexMiniFaqQuestionElementInnerLink,
  IndexMiniFaqQuestionElementList
};