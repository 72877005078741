export const commercialLinks = [
    {
        name: "Перевод аудио в текст",
        pathname: "/"
    }, {
        name: "Нейросеть для перевода аудио в текст",
        pathname: "/convert-audio2text"
    }, {
        name: "Перевод видео в текст",
        pathname: "/video2text"
    }, {
        name: "Нейросеть для транскрибации видео в текст",
        pathname: "/convert-video2text"
    }, {
        name: "Генератор субтитров",
        pathname: "/generate-subtitles"
    }, {
        name: "Расшифровка аудиозаписей для суда",
        pathname: "/convert-court-audiofiles"
    }, {
        name: "Расшифровка аудио и видео в текст",
        pathname: "/audio-video2text"
    }, {
        name: "Преобразование MP4 в текст онлайн",
        pathname: "/mp4totext"
    }, {
        name: "Перевод MP3 в текст онлайн",
        pathname: "/mp3totext"
    }, {
        name: "Расшифровка интервью онлайн",
        pathname: "/convert-interviews"
    }
];