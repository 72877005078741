import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const requestIntervalSlice = createSlice({
    name: 'requestInterval',
    initialState: {
        value: null
    },
    reducers: {
        setRequestInterval: (state, action: PayloadAction<ReturnType<typeof setInterval>>) => {
            state.value = action.payload;
        }
    }
})

export const { setRequestInterval } = requestIntervalSlice.actions;

export default requestIntervalSlice.reducer;