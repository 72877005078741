import styled from "styled-components";
import { Link } from "react-router-dom";
import pxIntoRem from "utils/pxIntoRem";

const CookiePopupBlock = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: ${pxIntoRem(30)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999999999;
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: ${pxIntoRem(12)};
    border: 2px solid transparent;
    background: linear-gradient(181deg, rgba(32, 34, 48, 0.7) 1.02%, rgba(32, 33, 41, 0) 128.15%) border-box;
    -webkit-mask:
                linear-gradient(#fff 0 0) padding-box, 
                linear-gradient(#fff 0 0);
            mask: 
                linear-gradient(#fff 0 0) padding-box, 
                linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    width: 80vw;
  }
`;

const CookiePopupBlockBackgroundLayer = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #171828;
  border-radius: ${pxIntoRem(12)};
`;

const CookiePopupIcon = styled.img`
  width: ${pxIntoRem(19)};
  height: ${pxIntoRem(19)};
  margin-top: ${pxIntoRem(16)};
  margin-left: ${pxIntoRem(16)};
  z-index: 999;
`;

const CookiePopupText = styled.p`
  color: #79768B;
  font-family: Mulish;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: ${pxIntoRem(16)};
  z-index: 999;
  span {
    color: #FFF;
  }
  @media (max-width: 500px) {
    margin-top: ${pxIntoRem(10)};
  }
`;

const CookiePopupLink = styled(Link)`
  color: #79768B;
  font-family: Mulish;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: ${pxIntoRem(4)};
  z-index: 999;
  color: #FFF;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    color: #1683E2;
  }
`;

const CookiePopupClose = styled.button`
  position: absolute;
  width: ${pxIntoRem(24)};
  height: ${pxIntoRem(24)};
  top: ${pxIntoRem(10)};
  right: ${pxIntoRem(10)};
  background: transparent;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const CookiePopupCloseIcon = styled.svg`
  width: ${pxIntoRem(24)};
  height: ${pxIntoRem(24)};
  stroke: #79768B;
  transition: 0.3s;
  @media (min-width: 501px) {
    &:hover {
      stroke: #FFF;
    }
  }
`;

const CookiePopupMainButton = styled.button`
  display: flex;
  position: relative;
  width: ${pxIntoRem(212)};
  height: ${pxIntoRem(42)};
  padding: ${pxIntoRem(10)} ${pxIntoRem(50)};
  justify-content: center;
  align-items: center;
  border-radius: ${pxIntoRem(8)};
  border: 1px solid #1683E2;
  background: transparent;
  margin: auto ${pxIntoRem(16)} auto auto;
  color: #FFF;
  font-family: Mulish, sans-serif;
  font-size: ${pxIntoRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s;
  @media (min-width: 501px) {
    &:hover {
      background: rgba(22, 131, 226, 0.10);
      box-shadow: 0px 0px 9px 0px rgba(22, 131, 226, 0.50) inset;
      transition: 0.3s;
    }
  }
  @media (max-width: 500px) {
    margin: auto ${pxIntoRem(16)} ${pxIntoRem(16)} ${pxIntoRem(16)};
    width: auto;
  }
`;

export {
  CookiePopupBlock,
  CookiePopupBlockBackgroundLayer,
  CookiePopupIcon,
  CookiePopupText,
  CookiePopupLink,
  CookiePopupClose,
  CookiePopupCloseIcon,
  CookiePopupMainButton
};