export const indexMainContent = {
    "/": {
        title: "Транскрибация аудио в текст",
        text1st: "Простой инструмент для перевода аудио- и видеозаписей в текст или субтитры за несколько минут"
    },
    "/convert-audio2text": {
        title: "Нейросеть для перевода аудио в текст",
        text1st: "Автоматическая расшифровка видео в текст для любых целей: работы, учебы, развлечений. Создавайте субтитры и описания к роликам быстро и просто. "
    },
    "/video2text": {
        title: "Перевод видео в текст онлайн",
        text1st: "Автоматическая расшифровка видео в текст для любых целей: работы, учебы, развлечений. Создавайте субтитры и описания к роликам быстро и просто. "
    },
    "/convert-video2text": {
        title: "Нейросеть для транскрибации видео в текст",
        text1st: "Транскрибация видео с помощью искусственного интеллекта в режиме реального времени. Получите готовую к использованию расшифровку за несколько минут. "
    },
    "/generate-subtitles": {
        title: "Генератор субтитров",
        text1st: "Создайте субтитры в несколько шагов и сделайте свои видео более интересными и наглядными "
    },
    "/convert-court-audiofiles": {
        title: "Расшифровка аудиозаписей для суда",
        text1st: "Перевод записей судебного процесса и материалов дела из аудиоформата в текстовую версию. Гарантируем высокую точность расшифровки и быстрое преобразование речи. "
    },
    "/audio-video2text": {
        title: "Расшифровка аудио и видео в текст",
        text1st: "Транскрибируйте файлы с лекциями, записями переговоров и встреч, заседаний суда быстро и легко. Сервис поддерживает разные форматы видео и аудио."
    },
    "/mp4totext": {
        title: "Преобразование MP4 в текст онлайн ",
        text1st: "Сервис на базе технологии искусственного интеллекта конвертирует MP4 в текст быстро и точно. "
    },
    "/mp3totext": {
        title: "Перевод MP3 в текст онлайн",
        text1st: "Конвертируйте аудио и видео в текст или субтитры за считанные минуты"
    },
    "/convert-interviews": {
        title: "Расшифровка интервью онлайн",
        text1st: "Нейросеть облегчает расшифровку интервью и подготовку материалов к публикации. Сервис подходит профессиональным журналистам, редакторам, копирайтерам."
    }
};