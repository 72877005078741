import styled, { keyframes } from "styled-components";
import pxIntoRem from "utils/pxIntoRem";

const LoadingAnimation = keyframes`
  0% { fillOpacity: 1; r: 3; }
  16.666% { fillOpacity: 0.9; r: 2.9; }
  16.667% { fillOpacity: 0.8; r: 2.8; }
  33.333% { fillOpacity: 0.7; r: 2.7; }
  50% { fillOpacity: 0.6; r: 2.6; }
  66.666% { fillOpacity: 0.5; r: 2.5; }
  66.666% { fillOpacity: 0.4; r: 2.4; }
  83.333% { fillOpacity: 0.3; r: 2.3; }
  100% { fillOpacity: 0.2; r: 2.2; }
`;

const LoadingIcon = styled.svg`
  width: ${pxIntoRem(48)};
  height: ${pxIntoRem(66)};
  margin-left: ${pxIntoRem(20)};
  .upload_circle_1 {
    animation: ${LoadingAnimation} 1.5s infinite;
  }
  .upload_circle_2 {
    animation: ${LoadingAnimation} 1.5s infinite 0.25s;
  }
  .upload_circle_3 {
    animation: ${LoadingAnimation} 1.5s infinite 0.5s;
  }
  .upload_circle_4 {
    animation: ${LoadingAnimation} 1.5s infinite 0.75s;
  }
  .upload_circle_5 {
    animation: ${LoadingAnimation} 1.5s infinite 1s;
  }
  .upload_circle_6 {
    animation: ${LoadingAnimation} 1.5s infinite 1.25s;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const LoadingIconMobile = styled.svg`
  display: none;
  width: ${pxIntoRem(38)};
  height: ${pxIntoRem(50)};
  .upload_circle_1 {
    animation: ${LoadingAnimation} 1.5s infinite;
  }
  .upload_circle_2 {
    animation: ${LoadingAnimation} 1.5s infinite 0.25s;
  }
  .upload_circle_3 {
    animation: ${LoadingAnimation} 1.5s infinite 0.5s;
  }
  .upload_circle_4 {
    animation: ${LoadingAnimation} 1.5s infinite 0.75s;
  }
  .upload_circle_5 {
    animation: ${LoadingAnimation} 1.5s infinite 1s;
  }
  .upload_circle_6 {
    animation: ${LoadingAnimation} 1.5s infinite 1.25s;
  }
  @media (max-width: 500px) {
    display: block;
  }
`;

const TooltipIcon = styled.svg`
  width: ${pxIntoRem(24)};
  height: ${pxIntoRem(24)};
  fill: #1B1D2C;
`;

const InputButtonIcon = styled.svg`
  display: flex;
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  stroke: #393952;
`;

export {
  LoadingIcon,
  LoadingIconMobile,
  TooltipIcon,
  InputButtonIcon
};