import { FC, PropsWithChildren, useEffect, useState } from "react";
import { LandingBlock, LandingBody } from "./LandingStyles";
import LandingHeader from "./Components/LandingHeader/LandingHeader";
import LandingFooter from "./Components/LandingFooter/LandingFooter";
import CookiePopup from "components/Popups/CookiePopup/CookiePopup";
import { isNewUser } from "../../utils/cookieManager";

const Landing: FC<PropsWithChildren> = ({ children }) => {
    const [showCookiePopup, setShowCookiePopup] = useState(false);

    useEffect(() => {
        if (isNewUser()) {
            setShowCookiePopup(true);
        }
    }, []);

    return (
        <LandingBlock id="landing_block">
            <LandingBody>
                <LandingHeader />
                <main>
                    {children}
                </main>
                {showCookiePopup && <CookiePopup setShowCookiePopup={setShowCookiePopup} />}
                <LandingFooter />
            </LandingBody>
        </LandingBlock>
    );
}

export default Landing;
