import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import {
    IndexFeaturesContent,
    IndexFeaturesTitle,
    IndexFeaturesBody,
    IndexFeaturesGrid,
    IndexFeaturesCard,
    IndexFeaturesCardBackground,
    IndexFeaturesCardPattern,
    IndexFeaturesCardPicture,
    IndexFeaturesCardIcon,
    IndexFeaturesCardTitleWrapper,
    IndexFeaturesCardTitle,
    IndexFeaturesCardText,
    IndexFeaturesTooltipButton,
    IndexFeaturesTooltipBlock,
    IndexFeaturesTooltipBlockText
} from "./IndexFeaturesStyles";
import { TooltipIconSVG } from "components/SvgComponents/TooltipIconSVG";
import { featuresContent } from "content/featuresContent";
import Portal from "components/PortalComponent/PortalComponent";
import * as DOMPurify from 'dompurify';

interface IComboBoxCoords {
    xOffset: number;
    yOffset: number;
}

interface IIndexFeaturesContent {
    title: string;
    titleSecondLine?: string;
    text: string;
    imageUrl: string;
    mobileImageUrl: string;
    type: string;
    tooltip?: boolean;
}

const IndexFeaturesCardsComponent = () => {
    const [content, setContent] = useState<IIndexFeaturesContent[]>(null);
    const [comboBoxCoords, setComboBoxCoords] = useState<IComboBoxCoords>({ xOffset: 0, yOffset: 0 });
    const location = useLocation();

    useEffect(() => {
        const root = document.getElementById("root") as HTMLElement;
        root.addEventListener("scroll", hideTip);
        return () => 
            root.removeEventListener("scroll", hideTip);
    }, []);

    useEffect(() => {
        const root = document.getElementById("root") as HTMLElement;
        root.addEventListener("touchmove", hideTip);
        return () => 
            root.removeEventListener("touchmove", hideTip);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', hideTip);
        return () => 
            window.removeEventListener('resize', hideTip);
    }, []);

    useEffect(() => {
        const content = featuresContent[location.pathname];
        setContent(content);
    }, [location]);

    const showTip = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const buttonCoords = e.currentTarget.getBoundingClientRect();
        const tooltip = document.getElementById("tooltip_block") as HTMLElement;
        const tooltipRect = tooltip.getBoundingClientRect();
        tooltip.style.visibility = "visible";
        setComboBoxCoords({
            xOffset: buttonCoords.left + (buttonCoords.width / 2) - (tooltipRect.width / 2) ,
            yOffset: buttonCoords.top - tooltipRect.height
        });
    };
    
    const hideTip = (): void => {
        const tooltip = document.getElementById("tooltip_block") as HTMLElement;
        setTimeout(() => {
            tooltip.style.visibility = "hidden";
        }, 500);
    };

    if (content) {
        return (
            <IndexFeaturesGrid>
                {content.map((item: IIndexFeaturesContent, idx) => {
                    return(
                        <IndexFeaturesCard key={idx} className={item.type}>
                            <IndexFeaturesCardBackground />
                            <IndexFeaturesCardPicture>
                                <source media="(max-width:500px)" srcSet={item.mobileImageUrl} />
                                <IndexFeaturesCardIcon alt="accurate" src={item.imageUrl} />
                            </IndexFeaturesCardPicture>
                            <IndexFeaturesCardPattern 
                                alt="pattern" 
                                src="/images/card-pattern.webp"
                            />
                            <IndexFeaturesCardTitleWrapper className={item.titleSecondLine ? "first_line" : ""}>
                                <IndexFeaturesCardTitle 
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.title) }}
                                />
                                {item.tooltip && !item.titleSecondLine && (
                                    <IndexFeaturesTooltipButton
                                        type="button"
                                        onMouseEnter={(e) => { showTip(e); }} 
                                        onMouseLeave={hideTip}
                                        onClick={(e) => { showTip(e); }}
                                    >
                                        <TooltipIconSVG />
                                        
                                    </IndexFeaturesTooltipButton>
                                )}
                            </IndexFeaturesCardTitleWrapper>
                            {item.titleSecondLine && (
                                <IndexFeaturesCardTitleWrapper className="second_line">
                                    <IndexFeaturesCardTitle>
                                        {item.titleSecondLine}
                                    </IndexFeaturesCardTitle>
                                    {item.tooltip && (
                                        <IndexFeaturesTooltipButton
                                            type="button"
                                            onMouseEnter={(e) => { showTip(e); }} 
                                            onMouseLeave={hideTip}
                                            onClick={(e) => { showTip(e); }}
                                        >
                                            <TooltipIconSVG />
                                            
                                        </IndexFeaturesTooltipButton>
                                    )}
                                </IndexFeaturesCardTitleWrapper>
                            )}
                            <Portal>
                                <IndexFeaturesTooltipBlock 
                                    id="tooltip_block"
                                    $xOffset={comboBoxCoords.xOffset}
                                    $yOffset={comboBoxCoords.yOffset}
                                    >
                                    <IndexFeaturesTooltipBlockText>
                                        Поддерживается большинство медиа форматов. По запросу добавим нужный формат.
                                    </IndexFeaturesTooltipBlockText>
                                </IndexFeaturesTooltipBlock>
                            </Portal>
                            <IndexFeaturesCardText>
                                {item.text}
                            </IndexFeaturesCardText>
                            
                        </IndexFeaturesCard>
                    );
                })}
            </IndexFeaturesGrid>
        );
    }
}

const IndexFeaturesComponent = () => {
    return (
        <IndexFeaturesContent>
            <section id="features">
                <IndexFeaturesTitle>
                    Возможности<br/>Semantix
                </IndexFeaturesTitle>
                <IndexFeaturesBody>
                    <IndexFeaturesCardsComponent />
                </IndexFeaturesBody>
            </section>
        </IndexFeaturesContent>
    );
}

export default IndexFeaturesComponent;