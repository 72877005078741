import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ILanguageOptionObj {
    id: number;
    ruName: string;
}

const languageOptionSlice = createSlice({
    name: 'languageOption',
    initialState: {
        value: {
            id: 2,
            ruName: "Русский"
        }
    },
    reducers: {
        setLanguageOption: (state, action: PayloadAction<ILanguageOptionObj | null>) => {
            state.value = action.payload;
        }
    }
})

export const { setLanguageOption } = languageOptionSlice.actions;

export default languageOptionSlice.reducer;