export const featuresContent = {
    "/": [
        {
            title: "Точность расшифровки — <span>95%</span>",
            text: "Программа для перевода аудио и видео в текст автоматически нейтрализует посторонние шумы и увеличивает точность расшифровки",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        }, {
            title: "Обработка часовой записи за <span>10 минут</span>",
            text: "Быстрая транскрибация аудио даже при высокой плотности речи к записи",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: "lowered_card"
        }, {
            title: "Расстановка знаков препинания",
            text: "После расшифровки аудио сервис сделает текст более грамотным и улучшит его восприятие при чтении",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: ""
        }, {
            title: "Транскрибация разных форматов",
            text: "Конвертер поддерживает видеоролики MP4, MKV, FLV, AVI, MOV, WMV и аудио M4A, MP3, OGG, AAC, WAV, FLAC, WMA",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "raised_card"
        }, {
            title: "Экспорт в форматах<br /><span>docx, txt, srt</span>",
            text: "После расшифровки аудиозаписи текст можно сохранить в удобном формате — docx, txt или srt для субтитров на YouTube",
            imageUrl: "/images/feature-export.svg",
            mobileImageUrl: "/images/feature-export.webp",
            type: ""
        }, {
            title: "Безопасность",
            text: "Мы дорожим безопасностью ваших данных, поэтому сервис шифрует все файлы при хранении и передаче в соответствии с лучшими практиками в индустрии",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: "raised_card",
            tooltip: true
        }, {
            title: "Тайм-коды",
            text: "Перевод речи в текст осуществляется с указанием временных кодов: так удобнее ориентироваться в полученных субтитрах",
            imageUrl: "/images/feature-timecode.svg",
            mobileImageUrl: "/images/feature-timecode.webp",
            type: "raised_card"
        }, {
            title: "Редактирование расшифровки",
            text: "Исправлять текст можно прямо в браузере: так документ после скачивания будет сразу готов к использованию",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: ""
        }, {
            title: "Бесплатная транскрибация",
            text: "Протестировать преобразование аудио можно бесплатно без внесения платежных данных: нужно только зарегистрироваться на сайте",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: "raised_card"
        }
    ],
    "/convert-audio2text": [
        {
            title: "Высокая точность расшифровки",
            text: "Благодаря шумоподавлению и нормализации голоса, онлайн-сервис выполняет транскрибацию с точностью до 95%",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },{
            title: "Экономия времени",
            text: "Часовой файл с высокой плотностью речи онлайн-конвертер обрабатывает за 10 минут",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: "lowered_card"
        },
        {
            title: "Редактирование текста",
            text: "Semantix расставляет знаки препинания для более легкого чтения. Также сделать правки в документе можно прямо в браузере",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: ""
        },
        {
            title: "Указание времени в расшифровке",
            text: "Онлайн-сервис расставляет тайм-коды, чтобы в тексте было проще ориентироваться",
            imageUrl: "/images/feature-timecode.svg",
            mobileImageUrl: "/images/feature-timecode.webp",
            type: "raised_card"
        },
        {
            title: "Бесплатные минуты",
            text: "Зарегистрируйтесь на сайте и протестируйте нейросеть для перевода аудио в текст бесплатно",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: ""
        },
        {
            title: "Поддержка разных",
            titleSecondLine: "форматов аудио",
            text: "Сервис может расшифровать файлы M4A, MP3, OGG, AAC, WAV, FLAC, WMA",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "raised_card",
            tooltip: true
        },
        {
            title: "Расстановка знаков препинания",
            text: "После перевода аудио в текст программа расставит знаки препинания для удобной работы с расшифровкой",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: "raised_card"
        },
        {
            title: "Экспорт в разных форматах",
            text: "Скачать документ с текстом можно в формате docx, txt, srt",
            imageUrl: "/images/feature-export.svg",
            mobileImageUrl: "/images/feature-export.webp",
            type: ""
        },
        {
            title: "Расстановка тайм-кодов",
            text: "Чтобы можно было использовать переведенный текст в качестве субтитров, сервис расставляет тайм-коды",
            imageUrl: "/images/feature-timecode.svg",
            mobileImageUrl: "/images/feature-timecode.webp",
            type: "raised_card"
        }
    ],
    "/video2text": [
        {
            title: "Высокая точность <br />перевода",
            text: "Благодаря шумоподавлению и функции нормализации голоса, инструмент точно транскрибирует видео даже плохого качества",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },{
            title: "Быстрая расшифровка длинных видео",
            text: "Даже если вы загрузите видео длиной в 1 час с высокой плотностью речи, сервис преобразует его в текст всего за 10 минут",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: "lowered_card"
        },
        {
            title: "Использование в разных сферах",
            text: "Генератор видео в текст решает любые задачи: от преобразования ролика на YouTube до транскрибирования многочасового судебного заседания",
            imageUrl: "/images/feature-export.svg",
            mobileImageUrl: "/images/feature-export.webp",
            type: ""
        },
        {
            title: "Высокий уровень безопасности инструмента",
            text: "Сервис Semantix защищает данные пользователей с помощью шифрования и аутентификации на сайте. Все исходники видео хранятся в надежном облаке",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: "raised_card"
        },
        {
            title: "Встроенные тайм-коды",
            text: "Чтобы расшифрованный текст было легче читать и понимать, инструмент автоматически добавляет метки времени, которые соответствует тайм-кодам на видео",
            imageUrl: "/images/feature-timecode.svg",
            mobileImageUrl: "/images/feature-timecode.webp",
            type: ""
        },
        {
            title: "Онлайн-редактор для изменения текста",
            text: "Вы можете прямо в браузере редактировать текст, чтобы получить полностью готовый к использованию файл",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: "raised_card"
        },
        {
            title: "Самые популярные",
            titleSecondLine: "форматы",
            text: "В конвертер можно добавить такие форматы видео: MP4, MKV, FLV, AVI, MOV, WMV. Также инструмент поддерживает аудио в форматах M4A, MP3, OGG, AAC, WAV, FLAC, WMA",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "raised_card",
            tooltip: true
        },
        {
            title: "Расстановка знаков препинания",
            text: "Чтобы вам было удобнее работать с расшифрованным файлом, сервис автоматически расставит знаки препинания",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: ""
        },
        {
            title: "Бесплатная конвертация видео",
            text: "Зарегистрируйтесь на сайте и получите возможность бесплатно расшифровать первые видео",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: "raised_card"
        }
    ],
    "/convert-video2text": [
        {
            title: "Быстрое транскрибирование",
            text: "Часовое видео конвертер переводит в текст примерно за 10 минут. Чем меньше файл, тем быстрее сервис преобразует его",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: ""
        },{
            title: "Безопасный инструмент",
            text: "Автоматическая расшифровка видео происходит на наших защищенных серверах, а файл можно удалить в любой момент",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: "lowered_card"
        },
        {
            title: "Точность перевода — <br /><span>95%</span>",
            text: "С помощью конвертера вы получите текст, который максимально совпадает с аудио или видео",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },
        {
            title: "Расстановка тайм-кодов",
            text: "Сервис автоматически расставит временные отметки, чтобы вы могли быстро и легко встроить субтитры в видео",
            imageUrl: "/images/feature-timecode.svg",
            mobileImageUrl: "/images/feature-timecode.webp",
            type: "raised_card"
        },
        {
            title: "Повышение качества расшифровки",
            text: "В Semantix есть дополнительные инструменты для улучшения транскрибирования: подавление шума, нормализация голоса, частотный фильтр",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: ""
        },
        {
            title: "Транскрибация любых",
            titleSecondLine: "файлов",
            text: "Конвертер поддерживает видео форматов MP4, MKV, FLV, AVI, MOV, WMV и аудио M4A, MP3, OGG, AAC, WAV, FLAC, WMA.",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "raised_card",
            tooltip: true
        },
        {
            title: "Экономия бюджета",
            text: "Нанимать специалиста по транскрибированию дорого. Наш инструмент стоит в разы дешевле услуг профессионала и справляется с задачей с минимальными недочетами",
            imageUrl: "/images/feature-affordable.svg",
            mobileImageUrl: "/images/feature-affordable.webp",
            type: "raised_card"
        },
        {
            title: "Текст в разных форматах",
            text: "Вы можете скачать расшифровку в форматах docx, txt и srt, чтобы сразу вставить субтитры в видео на Youtube",
            imageUrl: "/images/feature-export.svg",
            mobileImageUrl: "/images/feature-export.webp",
            type: ""
        },
        {
            title: "Бесплатные минуты",
            text: "Зарегистрируйтесь в приложении и бесплатно протестируйте нейросеть для транскрибации видео в текст",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: "raised_card"
        }
    ],
    "/generate-subtitles": [
        {
            title: "Высокая точность распознавания",
            text: "Даже есть в файле есть посторонние шумы, люди говорят невнятно, а голоса слышны плохо, точность расшифровки достигает 95%",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },{
            title: "Высокая скорость генерации субтитров",
            text: "Один час видео или аудио с высокой плотностью речи конвертер превратит в субтитры примерно на час. Это быстрее, чем вручную",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: "lowered_card"
        },
        {
            title: "Поддержка разных <br />языков",
            text: "Вы можете генерировать субтитры на русском, английском, немецком, итальянском или испанском языке",
            imageUrl: "/images/feature-multilang.svg",
            mobileImageUrl: "/images/feature-multilang.webp",
            type: ""
        },
        {
            title: "Редактирование субтитров в браузере",
            text: "После распознавания текста вы сможете редактировать субтитры, чтобы получить документ, полностью готовый к встраиванию в видео",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: "raised_card"
        },
        {
            title: "Доступность",
            text: "Нанимать специалиста по транскрибированию дорого. Автоматическая генерация субтитров обойдется дешевле, при этом результат будет таким же качественным",
            imageUrl: "/images/feature-affordable.svg",
            mobileImageUrl: "/images/feature-affordable.webp",
            type: ""
        },
        {
            title: "Разные форматы",
            titleSecondLine: "файлов",
            text: "Инструмент обрабатывает видео в форматах MP4, MKV, FLV, AVI, MOV, WMV и аудио в форматах M4A, MP3, OGG, AAC, WAV, FLAC, WMA.",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "raised_card",
            tooltip: true
        },
        {
            title: "Бесплатная генерация субтитров",
            text: "Зарегистрируйтесь на сайте, чтобы создавать субтитры из видео бесплатно: мы предоставляем тестовые минуты",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: "raised_card"
        },
        {
            title: "Расстановка тайм-кодов и знаков препинания",
            text: "После расшифровки сервис автоматически расставит тайм-коды и знаки препинания для удобства работы с субтитрами",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: ""
        },
        {
            title: "Безопасность",
            text: "Благодаря шифрованию и собственным серверам, ваши данные всегда в безопасности, к ним не получат доступа третьи лица",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: "raised_card"
        }
    ],
    "/convert-court-audiofiles": [
        {
            title: "Высокая точность расшифровки",
            text: "Сервис преобразует аудиозаписи в текст с точностью до 95%",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },{
            title: "Обработка разных",
            titleSecondLine: "форматов аудио",
            text: "Вы можете заказать расшифровку аудиозаписей в форматах M4A, MP3, OGG, AAC, WAV, FLAC, WMA и видео MP4, MKV, FLV, AVI, MOV, WMV",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "lowered_card",
            tooltip: true
        },
        {
            title: "Опции для повышения качества расшифровки",
            text: "Если на записи есть посторонние шумы, множество голосов, невнятная речь говорящего или другие дефекты, воспользуйтесь функциями шумоподавления или нормализации голоса",
            imageUrl: "/images/feature-options.svg",
            mobileImageUrl: "/images/feature-options.webp",
            type: ""
        },
        {
            title: "Расстановка времени в стенограмме",
            text: "Тайм-коды в расшифровке позволяют быстро определять, в какой момент времени были произнесены слова, и легко ориентироваться в тексте",
            imageUrl: "/images/feature-timecode.svg",
            mobileImageUrl: "/images/feature-timecode.webp",
            type: "raised_card"
        },
        {
            title: "Срочная расшифровка аудио",
            text: "Программа быстро транскрибирует аудиозаписи: часовой файл с высокой плотностью речи сервис обрабатывает около 10 минут",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: ""
        },
        {
            title: "Расстановка знаков препинания",
            text: "Сервис автоматически расставляет знаки препинания, чтобы работать с расшифрованным текстом было легко и удобно",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: "raised_card"
        },
        {
            title: "Безопасность",
            text: "Все данные шифруются и хранятся на собственных надежных серверах. Поэтому вся информация, которую вы загружаете в сервис, в безопасности",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: "raised_card"
        },
        {
            title: "Бесплатная расшифровка",
            text: "Зарегистрируйтесь на сайте и воспользуйтесь бесплатными минутами, чтобы протестировать программу",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: ""
        },
        {
            title: "Редактирование онлайн",
            text: "Если нужно, вы можете редактировать готовую расшифровку прямо в браузере с помощью онлайн-редактора",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: "raised_card"
        }
    ],
    "/audio-video2text": [
        {
            title: "Точность расшифровки — <span>95%</span>",
            text: "Сервис распознает даже акценты и нечеткие звуки, поэтому текст максимально совпадает с речью из файла",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },{
            title: "Быстрая обработка <br />файлов",
            text: "Часовую запись с высокой плотностью речи сервис транскрибирует примерно 10 минут",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: "lowered_card"
        },
        {
            title: "Безопасная работа",
            text: "Расшифровка файлов происходит на защищенных серверах Semantix. Также ваши данные в безопасности благодаря надежному шифрованию",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: ""
        },
        {
            title: "Дополнительные возможности",
            text: "Чтобы улучшить качество текста, вы можете воспользоваться такими возможностями, как подавление шума, нормализация голоса, частотный анализ",
            imageUrl: "/images/feature-options.svg",
            mobileImageUrl: "/images/feature-options.webp",
            type: "raised_card"
        },
        {
            title: "Расстановка знаков препинания",
            text: "Сервис автоматически расставляет знаки препинания и тайм-коды, чтобы субтитры было проще читать и редактировать",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: ""
        },
        {
            title: "Разные форматы файлов",
            titleSecondLine: "файлов",
            text: "Конвертер поддерживает популярные форматы: видео — MP4, MKV, FLV, AVI, MOV, WMV и аудио — M4A, MP3, OGG, AAC, WAV, FLAC, WMA",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "raised_card",
            tooltip: true
        },
        {
            title: "Исправление ошибок в браузере",
            text: "После извлечения текста из файла вы можете внести изменения или исправить ошибки в словах до скачивания прямо в браузере",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: "raised_card"
        },
        {
            title: "Экспорт документов в трех форматах",
            text: "Медиафайл с текстом можно загрузить на компьютер в трех форматах: docx, txt, srt. Последний подходит, чтобы быстро и удобно вставить субтитры на YouTube",
            imageUrl: "/images/feature-export.svg",
            mobileImageUrl: "/images/feature-export.webp",
            type: ""
        },
        {
            title: "Бесплатная расшифровка",
            text: "Зарегистрируйтесь на сайте, чтобы воспользоваться бесплатными минутами для транскрибации аудио и видео в текст",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: "raised_card"
        }
    ],
    "/mp4totext": [
        {
            title: "Высокая точность расшифровки",
            text: "Сервис использует возможности искусственного текста и транскрибирует аудио и видео MP4 с точностью до 95%",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },{
            title: "Быстрое преобразование файлов",
            text: "Программа обрабатывает MP4 за короткое время: часовое аудио или видео она расшифровывает за 10 минут",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: "lowered_card"
        },
        {
            title: "Поддержка разных языков",
            text: "Вы можете преобразовать аудио и видео на русском, английском, немецком, итальянском или испанском языке",
            imageUrl: "/images/feature-multilang.svg",
            mobileImageUrl: "/images/feature-multilang.webp",
            type: ""
        },
        {
            title: "Расстановка знаков препинания и тайм-кодов",
            text: "После преобразования текст легко читать и воспринимать, а также искать в нем нужную информацию",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: "raised_card"
        },
        {
            title: "Онлайн-редактор",
            text: "Результат расшифровки можно изменить прямо в браузере, чтобы скачать на компьютер полностью готовый к работе файл",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: ""
        },
        {
            title: "Высокий уровень безопасности",
            text: "Благодаря надежным алгоритмам шифрования, все данные, которые вы загружаете, надежно защищены",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: "raised_card"
        },
        {
            title: "Бесплатные минуты",
            text: "Зарегистрируйтесь в сервисе и получите возможность бесплатно расшифровать MP4 в текст",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: "raised_card"
        },
        {
            title: "Экспорт в разных форматах",
            text: "Сохраните расшифрованный документ на компьютер в удобном формате: docx, txt, srt",
            imageUrl: "/images/feature-export.svg",
            mobileImageUrl: "/images/feature-export.webp",
            type: ""
        },
        {
            title: "Экономия бюджета",
            text: "Преобразование MP4 в текст с помощью нашего сервиса стоит дешевле, чем услуги специалиста по транскрибации",
            imageUrl: "/images/feature-affordable.svg",
            mobileImageUrl: "/images/feature-affordable.webp",
            type: "raised_card"
        }
    ],
    "/mp3totext": [
        {
            title: "Легкое восприятие",
            text: "Автоматическая расстановка тайм-кодов и знаков препинания в тексте",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: ""
        },
        {
            title: "Быстрая обработка",
            text: "Сервис осуществляет быструю транскрибацию аудиозаписей, обрабатывая часовые файлы с высокой плотной речью всего за 10 минут.",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: "lowered_card"
        },
        {
            title: "Экономия средств",
            text: "Сервис стоит дешевле, чем услуги профессионалого транскрибатора",
            imageUrl: "/images/feature-affordable.svg",
            mobileImageUrl: "/images/feature-affordable.webp",
            type: ""
        },
        {
            title: "Несколько языков",
            text: "Сервис поддерживает русский, английский, испанский, итальянский, немецкий",
            imageUrl: "/images/feature-multilang.svg",
            mobileImageUrl: "/images/feature-multilang.webp",
            type: "raised_card"
        },
        {
            title: "Высокая точность",
            text: "Используя встроенные возможности, наш алгоритмы обучены точно транскрибировать даже при наличии шумов и низком качестве аудиозаписи.",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: ""
        },
        {
            title: "Безопасность",
            text: "Шифрование и безопасное хранение информации в облаке",
            imageUrl: "/images/feature-security.svg",
            mobileImageUrl: "/images/feature-security.webp",
            type: "raised_card"
        },
        {
            title: "Онлайн-редактор",
            text: "Возможность редактирования текста прямо в браузере",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: "raised_card"
        },
        {
            title: "Бесплатный конвертер",
            text: "Получите бесплатные минуты за регистрацию на сайте и протестируйте качество перевода",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: ""
        },
        {
            title: "Несколько форматов для скачивания",
            text: "Экспорт текста в разных форматах — docx, txt, srt",
            imageUrl: "/images/feature-export.svg",
            mobileImageUrl: "/images/feature-export.webp",
            type: "raised_card"
        }
    ],
    "/convert-interviews": [
        {
            title: "Быстрая расшифровка",
            text: "Если интервью длится 1 час, программа переведет его в текст за 10 минут",
            imageUrl: "/images/feature-fast.svg",
            mobileImageUrl: "/images/feature-fast.webp",
            type: ""
        },{
            title: "Высокое качество",
            text: "Точность транскрибации видео- и аудио достигает 95%",
            imageUrl: "/images/feature-accuracy.svg",
            mobileImageUrl: "/images/feature-accuracy.webp",
            type: "lowered_card"
        },
        {
            title: "Дополнительные функции",
            text: "Если аудио или видео плохого качества, можно воспользоваться функцией шумоподавления, нормализации голоса и частотным анализом",
            imageUrl: "/images/feature-options.svg",
            mobileImageUrl: "/images/feature-options.webp",
            type: ""
        },
        {
            title: "Аудио и видео",
            titleSecondLine: "разных форматов",
            text: "Semantix расшифровывает аудио в форматах M4A, MP3, OGG, AAC, WAV, FLAC, WMA и видео в форматах MP4, MKV, FLV, AVI, MOV, WMV",
            imageUrl: "/images/feature-formats.svg",
            mobileImageUrl: "/images/feature-formats.webp",
            type: "raised_card",
            tooltip: true
        },
        {
            title: "Онлайн-редактор",
            text: "Текст интервью можно редактировать прямо в браузере: убрать отдельные части, поправить опечатки, изменить формулировки",
            imageUrl: "/images/feature-edit.svg",
            mobileImageUrl: "/images/feature-edit.webp",
            type: ""
        },
        {
            title: "Расстановка знаков препинания",
            text: "После расшифровки аудио и видео сервис расставляет знаки препинания в тексте, чтобы повысить удобство чтения интервью",
            imageUrl: "/images/feature-punctuation.svg",
            mobileImageUrl: "/images/feature-punctuation.webp",
            type: "raised_card"
        },
        {
            title: "Тайм-коды",
            text: "Вы сможете быстро и легко вставить субтитры в видео с интервью благодаря временным отметкам",
            imageUrl: "/images/feature-timecode.svg",
            mobileImageUrl: "/images/feature-timecode.webp",
            type: "raised_card"
        },
        {
            title: "Бесплатная расшифровка",
            text: "Зарегистрируйтесь на сайте и получите бесплатные минуты, чтобы транскрибировать интервью онлайн",
            imageUrl: "/images/feature-tryfree.svg",
            mobileImageUrl: "/images/feature-tryfree.webp",
            type: ""
        },
        {
            title: "Поддержка разных <br />языков",
            text: "Сервис поддерживает русский, английский, испанский, итальянский, немецкий языки",
            imageUrl: "/images/feature-multilang.svg",
            mobileImageUrl: "/images/feature-multilang.webp",
            type: "raised_card"
        }
    ]
};