import { useLayoutEffect } from "react";
import { Pagination } from "swiper/modules";
import {
    IndexReviewsBlock,
    IndexReviewsBackgroundBlock,
    IndexReviewsTitle,
    IndexReviewsSwiperBlock,
    IndexReviewsSwiper,
    IndexReviewsSwiperSlide,
    IndexReviewsSwiperSlideContent,
    IndexReviewsSwiperSlideUserInfo,
    IndexReviewsSwiperSlideUserAvatar,
    IndexReviewsSwiperSlideUserName,
    IndexReviewsSwiperSlideText
} from "./IndexReviewsStyles";
import { userReviews } from "content/UserReviews";
import { avatarName } from "utils/avaterName";

interface IIndexReview {
    avatarUrl: string;
    username: string;
    text: string;
    reviewDate: string;
}

const IndexReviews = () => {
    useLayoutEffect(() => {
        const wrapper = document.getElementsByClassName("swiper-wrapper")[0] as HTMLElement;
        wrapper.style.display = "flex";
        wrapper.style.height = "fit-content";
    }, []);
    
    return (
        <IndexReviewsBlock>
            <IndexReviewsBackgroundBlock>
                <IndexReviewsTitle>
                    Отзывы наших <br className="mobile_break" />пользователей
                </IndexReviewsTitle>
                <IndexReviewsSwiperBlock>
                    <IndexReviewsSwiper
                        modules={[Pagination]}
                        allowTouchMove={true}
                        pagination={{ clickable: true }}
                        preventInteractionOnTransition={true}
                        initialSlide={0}
                        breakpoints={{
                            1000: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            },
                            501: {
                                slidesPerView: 3,
                                spaceBetween: 10
                            }, 
                            300: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            }
                        }}
                    >
                        {userReviews.map((review: IIndexReview, idx) => {
                            return(
                                <IndexReviewsSwiperSlide key={idx}>
                                    <IndexReviewsSwiperSlideContent>
                                        <IndexReviewsSwiperSlideUserInfo>
                                            <IndexReviewsSwiperSlideUserAvatar>
                                                {avatarName(review.username)}
                                            </IndexReviewsSwiperSlideUserAvatar>
                                            <IndexReviewsSwiperSlideUserName>{review.username}</IndexReviewsSwiperSlideUserName>
                                        </IndexReviewsSwiperSlideUserInfo>
                                        <IndexReviewsSwiperSlideText>{review.text}</IndexReviewsSwiperSlideText>
                                        <IndexReviewsSwiperSlideText className="review_date">{review.reviewDate}</IndexReviewsSwiperSlideText>
                                    </IndexReviewsSwiperSlideContent>
                                </IndexReviewsSwiperSlide>
                            );
                        })}
                    </IndexReviewsSwiper>
                </IndexReviewsSwiperBlock>
            </IndexReviewsBackgroundBlock>
        </IndexReviewsBlock>
    );
}

export default IndexReviews;