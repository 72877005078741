import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const balanceErrorSlice = createSlice({
    name: 'balanceError',
    initialState: {
        value: null
    },
    reducers: {
        setBalanceError: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        }
    }
})

export const { setBalanceError } = balanceErrorSlice.actions;

export default balanceErrorSlice.reducer;